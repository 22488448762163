<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="box-front">
          <div class="row">
            <div>
              <img src="../assets/img/fonetti-logo.png" class="center-block logo" alt="Fonetti Logo">
              <action-messages></action-messages>
            </div>
          </div>
          <div class="form-wrapper">
            <login-form v-model="form" @validated="login(form)" :is-loading="isLoading"></login-form>
            <div class="register-wrap">
              <p>Subscribe today for a <strong>free trial</strong> and benefit from generous early adopter pricing.</p>
              <router-link to="/register-school" tag="button" class="btn btn-primary btn-block">Register & Subscribe</router-link>
            </div>
          </div>
          <div class="form-wrapper">
            <router-link to="/forgotten" class="forgot-password-link">Forgotten Password</router-link>
          </div>
        </div>
      </div>
    </div>

    <modal title="Confirm" style="color: #555" v-if="modal.isVisible('confirm-accept-invite')" @close="closeConfirmModal">
      <h3 class="text-center">Accepting the invite will delete your readers.<br>Do you still wish to accept?</h3>
      <div class="text-center">
        <loading-button :is-loading="isLoading" @click="runInviteProcess" class="btn btn-lg btn-yes btn-primary">Yes</loading-button>
        <button @click="declineInvite" class="btn btn-no btn-lg btn-primary">No</button>
      </div>
    </modal>
  </div>
</template>

<script>
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import InviteMixin from '@/components/mixins/InviteMixin';
import LoginForm from '@/components/forms/LoginForm';
import Form from '@/components/classes/Form';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';
import LoadingButton from '@/components/utils/LoadingButton';

export default {
  name: 'Login',
  mixins: [SubscriptionMixin, InviteMixin, CurrentUserMixin],
  components: {
    LoadingButton,
    LoginForm,
    modal: ModalJS,
  },
  data() {
    return {
      section: 'Login',
      isLoading: false,
      form: new Form({
        email: this.$store.state.emailAddress,
        password: '',
      }),
      invite: null,
      message: null,
      modal: new Modal({
        confirmAcceptInvite: false,
      }),
    };
  },
  mounted() {
    this.invite = window.localStorage.getItem('invite');
    if (this.$route.query.action === 'decline') {
      this.message = 'You have declined the invitation';
    }
    this.$apiResponse.showHeading(false);
  },
  methods: {
    async login(data) {
      this.isLoading = true;
      this.$store.dispatch('login', data)
        .then(() => {
          this.getMe()
            .then((user) => {
              if (this.invite) {
                // first, need to check if readers
                if (user.user_type === 'standard' && user.readers.length > 0) {
                  // show modal
                  this.isLoading = false;
                  this.modal.show('confirm-accept-invite');
                } else {
                  this.runInviteProcess();
                }
              } else {
                this.handleSubscriptionProcess()
                  .then((route) => {
                    this.$router.push(route);
                  });
              }
            });
        })
        .catch((err) => {
          this.$apiResponse.renderErrorMessage(err, err.response.data.error ? err.response.data.error : 'Oops something went wrong, please try again.');
          this.isLoading = false;
        });
    },
    declineInvite() {
      this.decline(this.invite);
      this.modal.hide('confirm-accept-invite');
      this.message = 'You have declined the invitation. <a href="https://www.fonetti.com/get-in-touch/">Contact us for more information</a>';
      this.isLoading = false;
    },
    runInviteProcess() {
      this.handleInviteProcess(this.invite)
        .then((route) => {
          this.$router.push(route);
        });
    },
    closeConfirmModal() {
      this.modal.hide('confirm-accept-invite');
      this.isLoading = false;
      this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.action-messages {
  margin-bottom: 15px;
}
.form-wrapper {
  display: flex;
  gap: 40px;
  > * {
    flex: 0 1 50%;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.register-wrap {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #ff7a0129;
  padding-left: 40px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
}
.forgot-password-link {
  text-align:center;
}
</style>
