import * as Fathom from 'fathom-client';

const defaultOptions = {
  siteID: null,
  router: null,
  store: null,
  disabled: false,
  fathomSettings: {
    auto: false, // Disables auto-tracking page views, as we'll be doing it manually to mess with the paths it sends
  },
};

class UseFathomPlugin {
  constructor(options) {
    if (options.siteID === null) {
      throw new Error('Missing Site ID for Fathom');
    }

    if (options.router === null) {
      throw new Error('Missing Vue Router in options');
    }

    if (options.router === null) {
      throw new Error('Missing Vuex Store in options');
    }

    this.siteID = options.siteID;
    this.router = options.router;
    this.store = options.store;
    this.isDisabled = options.disabled;

    this.installRouterGuard();
    Fathom.load(this.siteID, options.fathomSettings);
  }

  installRouterGuard() {
    this.router.afterEach((to) => {
      let toPath = to.path;
      let path = '';

      // Authenticated pages should start with the /school/:id prefix
      const schoolId = this.store.getters.getCurrentSchoolId;
      if (schoolId !== null) {
        path += `/schools/${schoolId}`;
      }

      // Then we add on the current page path, replacing /:id/ portions with /123/
      toPath = toPath.replace(/\/\d+(\/|$)/g, '/123/');

      // And replace UUIDs (book ids)
      toPath = toPath.replace(/\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}(\/|$)/g, '/123/');

      path += toPath;

      this.trackPageview({
        url: path,
      });
    });
  }

  trackPageview(options) {
    if (!this.isDisabled) {
      Fathom.trackPageview(options);
    }
  }

  trackGoal(id, value = 0) {
    if (!this.disabled) {
      Fathom.trackGoal(id, value);
    }
  }
}

export default {
  install(Vue, options) {
    const mergedOptions = { ...defaultOptions, ...options };
    const instance = new UseFathomPlugin(mergedOptions);
    Vue.prototype.$fathom = instance;
    Vue.fathom = instance;
  },
};
