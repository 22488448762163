export default {
  data() {
    return {};
  },
  methods: {
    isInSchool() {
      return this.$store.state.user.enterprise !== undefined && !this._.isEmpty(this.$store.state.user.enterprise);
    },
    getSubscriptions(schoolId) {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}/subscriptions`)
          .then((response) => {
            resolve(response.data.subscriptions);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    handleSubscriptionProcess() {
      return new Promise((resolve) => {
        this.$store.commit('HAS_PASSED_SUBSCRIPTION_SCREENING', true);
        const school = this.$store.state.user.enterprise;
        if (this.$store.state.user.user_type !== 'enterprise') resolve('/subscription/warning');
        if (this._.isEmpty(school)) resolve('/register-school');

        resolve('/');
      });
    },
    selectTrial() {
      this.$http.post(`enterprises/${this.school.id}/subscriptions/trial`, this.form)
        .then(() => {
          this.$store.commit('FROM_TRIAL', true);
          this.$router.push('/');
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    selectPackage(what) {
      switch (what) {
        case 'trial':
          this.selectTrial();
          break;
        default:
          this.$store.commit('SET_SELECTED_SUBSCRIPTION_PACKAGE_ID', what);
          this.$router.push('fill-billing-details');
          break;
      }
    },
  },
};
