<template>
  <div class="container container-table">
    <div class="row" v-if="tokenIsNotAuthorised">
      <div class="col-md-10">
        <div class="setup-form box-front" >
          <div class="box-header box-header-with-boarder">
            <div class="action-messages">
              <router-link to="/login"><img src="../assets/img/fonetti-logo.png" class="center-block logo"></router-link>
              <div role="alert" class="alert alert-danger">
                <h4><i class="icon icon-ban"></i> Information </h4>
                <div>
                  <p>This token is no longer authorised</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import SchoolMixins from '@/components/mixins/SchoolMixins';
// import config from '@/config';

export default {
  name: 'setup',
  mixins: [CurrentUserMixin, SchoolMixins],
  data() {
    return {
      form: {
        password: '',
      },
      stage: 0,
      school: {},
      tokenIsNotAuthorised: false,
    };
  },
  mounted() {
    // const re = new RegExp(config.adminPanelURL);
    // if (!re.test(document.referrer)) {
    //   this.$router.push('/logout');
    //   return;
    // }
    this.$store.dispatch('setToken', this.$route.params.token).then(() => {
      this.getMe()
        .then(() => {
          this.$router.push('/').catch(() => {});
        })
        .catch(() => {
          this.tokenIsNotAuthorised = true;
        });
    });
  },
  methods: {
  },
};
</script>
