export default {
  callingAPI: false,
  isLoading: false,
  searching: '',
  // serverURI: 'http://10.110.1.136:8080',
  serverURI: 'http://ec2-52-88-56-167.us-west-2.compute.amazonaws.com/v1/',
  user: null,
  token: localStorage.getItem('token') || null,
  emailAddress: '',
  origin: null,
  userInfo: {
    messages: [{ 1: 'test', 2: 'test' }],
    notifications: [],
    tasks: [],
  },
  termsAndConditionsAccepted: false,
  hasSubscription: false,
  hasPassedSubscriptionScreening: false,
  currentGroup: null,
  currentSchool: null, // todo "current" makes no sense in the SP
  school: null,
  currentChartsGroup: null,
  defaultDateRange: (() => {
    const savedValue = window.localStorage.getItem('default_date_range');
    return savedValue === null ? savedValue : JSON.parse(savedValue);
  })(),
  rowsPerPage: localStorage.getItem('number_of_rows') || 50,
  currentPupil: null,
  currentBook: null,
  selectedSubscriptionPackageId: null,
  invoice: null,
  hasOpenInvoice: false,
  hasOutstandingInvoice: false,
  openInvoice: null,
  isNearingEndOfSubscription: false,
  subscriptionDaysLeft: null,
  fromTrial: false,
};
