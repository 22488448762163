<template>
  <!-- Main content -->
  <section class="content">
      <subscription-selector v-if="currentSchool" :school-id="currentSchool.id" @select="selectPackage">
        <template slot="invoices">
          <invoices />
        </template>
      </subscription-selector>
  </section>
  <!-- /.content -->
</template>

<script>
import { mapState } from 'vuex';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import StringUtils from '@/components/mixins/StringUtils';
import SubscriptionSelector from '@/components/partials/SubscriptionSelector';
import Invoices from '@/components/partials/Invoices';

export default {
  components: {
    Invoices,
    SubscriptionSelector,
  },
  mixins: [SubscriptionMixin, CurrentUserMixin, StringUtils],
  mounted() {
    if (!this.$authorization.isAuthorized('manage_subscription')) {
      this.$router.push('/');
    }
  },
  methods: {
    formatCurrency(number) {
      return Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(number);
    },
  },
  computed: {
    ...mapState([
      'isNearingEndOfSubscription',
      'subscriptionDaysLeft',
      'currentSchool',
    ]),
  },
};
</script>
<style scoped>
.content {
  margin: 25px;
}
/* .contact form {
  margin-top: 40px;
} */
</style>
