<template>
  <div class="container">
    <div class="row">
      <div class="col-centered col-md-8">
        <div class="setup-form box-front">
          <img src="../assets/img/fonetti-logo.png" class="center-block logo">
          <action-messages></action-messages>
          <h2 class="mb-1">Register your school</h2>
          <register-school-form v-model="form" @validated="setupSchool"></register-school-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterSchoolForm from '@/components/forms/RegisterSchoolForm';
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';

export default {
  name: 'register-school',
  mixins: [CurrentUserMixins],
  components: {
    RegisterSchoolForm,
  },
  data() {
    return {
      form: {
        school: {
          name: null,
          urn: null,
        },
        contact: {
          address_line_1: null,
          address_line_2: null,
          address_line_3: null,
          address_line_4: null,
          postcode: null,
          country_code: null,
          telephone: null,
        },
        user: {
          first_name: null,
          last_name: null,
          email: null,
          password: null,
          eula: false,
          tc: false,
        },
      },
    };
  },
  methods: {
    setupSchool() {
      this.$store.commit('IS_LOADING', true);
      this.$http.post('enterprises/setup', this.form)
        .then((response) => {
          const school = response.data.enterprise;
          this.$store.commit('SET_CURRENT_SCHOOL', school);
          this.$store.dispatch('login', this.form.user)
            .then(() => {
              this.getMe()
                .then(() => {
                  this.$router.push('/select-subscription');
                });
            })
            .catch((err) => {
              this.$apiResponse.renderErrorMessage(err);
            })
            .finally(() => this.$store.commit('IS_LOADING', false));
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => this.$store.commit('IS_LOADING', false));
    },
  },
};
</script>
