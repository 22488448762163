<template>
    <div class="box" :class="boxClass">
        <div class="box-header">
            <h3 class="box-title">{{ title }}</h3>
        </div>
        <div class="box-body no-padding table-responsive">
            <table class="table table-borderless">
                <tbody>
                <tr>
                    <th class="cell-25">Contact Name</th>
                    <td>{{ value.name }}</td>
                </tr>
                <tr>
                    <th class="cell-25">Job Role</th>
                    <td>{{ value.job_role }}</td>
                </tr>
                <tr>
                    <th>Address Line 1</th>
                    <td>{{ value.address.line1 }}</td>
                </tr>
                <tr>
                    <th>Address Line 2</th>
                    <td>{{ value.address.line2 }}</td>
                </tr>
                <tr>
                    <th>Address Line 3</th>
                    <td>{{ value.address.line3}}</td>
                </tr>
                <tr v-if="value.address.line4 && value.address.line4 !== ''">
                    <th>Address Line 4</th>
                    <td>{{ value.address.line4 }}</td>
                </tr>
                <tr>
                    <th>Postcode</th>
                    <td>{{ value.address.postcode }}</td>
                </tr>
                <tr>
                    <th>Country</th>
                    <td>{{ countries.getName(value.address.countryCode, 'en') }}</td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>{{ value.phone }}</td>
                </tr>
                <tr>
                    <th>Email</th>
                    <td>{{ value.email }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'ContactDetails',
  props: {
    value: {},
    title: String,
    boxClass: String,
  },
  data() {
    return {
      countries,
    };
  },
};
</script>
