<template>
  <div class="container">
    <div class="row">
      <div class="col-centered col-md-8">
        <div class="select-subscription box-front">
          <img src="../../assets/img/fonetti-logo.png" class="center-block logo">
          <action-messages></action-messages>
          <template v-if="hasAccess && invoiceToPay">
            <p v-if="contact_email" class="mb-2">Your invoice has been generated and sent to {{contact_email}}. You may download a copy of the invoice below. The invoice can be paid online via debit/credit card, or via bank transfer.</p>
            <p v-else class="mb-2">You have an outstanding invoice that was not paid by its due date, you may download a copy of that invoice below. The invoice can be paid online via debit/credit card, or via bank transfer.</p>
            <div class="invoice-buttons">
                <a class="btn btn-success btn-lg" target="_blank" :href="invoiceToPay['invoice_pdf']">Download Invoice</a>
                <a class="btn btn-success btn-lg" target="_blank" :href="invoiceToPay['hosted_invoice_url']"><i class="fa fa-credit-card-alt fa-xs me-3"></i>Pay Now</a>
            </div>
            <a class="btn btn-primary btn-block enter-school-portal" v-if="showSPLink" @click="$router.push('/')">Enter School Portal</a>
          </template>
          <template v-if="hasAccess === false">
            <p>Please contact your school administrator to resolve this issue.</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'feedback-subscription',
  props: ['contact_email'],
  data() {
    return {
      invoiceToPay: null,
      email: null,
      showSPLink: true,
      hasAccess: null,
    };
  },
  mounted() {
    this.hasAccess = this.$authorization.isAuthorized('manage_subscription');
    if (this.openInvoice) {
      this.invoiceToPay = this.openInvoice;
    } else {
      this.invoiceToPay = this.invoice;
    }
    if (this.invoiceToPay === null) {
      this.$router.push('/');
      return;
    }
    if (this.$route.query.show_sp_link === 'false') {
      this.showSPLink = false;
    }
    this.$store.dispatch('resetInvoices');
  },
  destroyed() {
    this.$store.dispatch('resetInvoices');
  },
  computed: {
    ...mapState([
      'openInvoice',
      'invoice',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.invoice-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
.btn.enter-school-portal {
  margin-top: 20px;
  font-size: 1.2em;
}

// Fix the btn-lg because
</style>
