<template>
  <!-- Main content -->
  <section class="content">
    <div class="row">
        <div class="col-md-12">
          <div class="box box-info">
            <!-- Input Addons -->
            <div class="box-header with-border">
              <h3 class="box-title">Edit Details</h3>
            </div>
            <div class="box-body">
              <account-form v-model="form" :is-loading="isLoading" @validated="updateAccount"></account-form>
            </div>
          </div>
        </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import Form from '@/components/classes/Form';
import AccountForm from '@/components/forms/AccountForm';

export default {
  name: 'Register',
  data() {
    return {
      section: 'Account',
      form: new Form({
        first_name: this.$store.state.user.first_name,
        last_name: this.$store.state.user.last_name,
        email: this.$store.state.user.email,
        weekly_school_digest_optin: this.$store.state.user.weekly_school_digest_optin_at !== null,
        password: '',
      }),
      isLoading: false,
    };
  },
  methods: {
    updateAccount() {
      this.isLoading = true;

      if (this.form.password.trim().length === 0) {
        delete this.form.password;
      }

      /* Making API call to authenticate a user */
      this.$http.post('users/me', this.form)
        .then(() => this.$http.get('users/me'))
        .then((response) => {
          const { user } = response.data;

          /* Setting user in the state and caching record to the localStorage */
          if (user) {
            this.$store.dispatch('setUser', user);
            this.$apiResponse.renderSuccessMessage(response, 'Details saved');
            this.isLoading = false;
          }
        })
        .catch((error) => {
          if (error.code === 400) {
            this.$apiResponse.renderErrorMessage(error, 'Duplicate email');
          } else {
            this.$apiResponse.renderErrorMessage(error, 'Details incorrect');
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.form.password = '';
        });
    },
  },
  components: {
    AccountForm,
    'account-form': AccountForm,
  },
};
</script>
<style>

</style>
