<template>
    <div class="container container-table">
        <div class="row">
            <div class="col-md-3 col-sm-1"></div>
            <div class="col-md-6 col-sm-10">
                <div class="box-front">
                    <img src="../assets/img/fonetti-logo.png" class="center-block logo">
                    <action-messages></action-messages>
                    <div v-if="action === 'warning'">
                        <div class="alert alert-warning">
                            <h2>Warning</h2>
                            <p>This is for school usage only, if you are an educator looking to register your school you will need to create a new account - <a href="https://www.fonetti.com/get-in-touch/">Contact us for more information</a>.</p>
                        </div>
                        <button class="btn btn-large btn-block" @click="$router.push('logout')">Logout</button>
                    </div>

                    <div v-if="action === 'register'">
                        <div v-if="$store.state.origin !== 'registration' && _.isEmpty($store.state.user.enterprise)" class="alert alert-warning">
                            <p class="text-bold"><i class="icon icon-warning"></i> You are not currently associated with a school.</p>
                            <p>If your school is already registered, please contact your school admin to gain access, otherwise you can register your school below.</p>

                        </div>
                        <h2>Register your school</h2>
                        <school-form v-model="school" @validated="createSchool" :is-loading="isLoading" origin="register"></school-form>
                    </div>

                    <div v-if="action === 'start'">
                        <h2>Thanks for registering your school</h2>
                        <p>Before you can continue, we'd like to talk to you about how you can get the best of Fonetti at your school. This also gives you the opportunity to ask us any questions you may have.</p>
                        <p>We also use this opportunity to verify and activate your account. You can use the form below to arrange a convenient time we should call or if you prefer we can contact you via email. We aim to contact everyone by their preferred method on the same working day.</p>
                        <subscription-contact-form v-model="form" :is-loading="isLoading" @validated="callSendForm('start')" v-if="showForm"></subscription-contact-form>
                    </div>

                    <div v-if="action === 'renew' && canManageSubscription">
                        <h2>Your subscription has expired</h2>
                        <p>It looks like your subscription to Fonetti has not been renewed. In order to use the portal we require you to have an active subscription</p>
                        <p>Currently we only support school subscription by invoice. Please use the form below to indicate a suitable time for us to contact you to arrange setting this up. We aim to call everyone back the same day.</p>
                        <subscription-contact-form v-model="form" :is-loading="isLoading" @validated="callSendForm('renew')" v-if="showForm"></subscription-contact-form>
                    </div>

                    <div v-if="action === 'renew' && !canManageSubscription">
                        <h2>Your subscription has expired</h2>
                        <p>It looks like your subscription to Fonetti has not been renewed. In order to use the portal we require you to have an active subscription</p>
                        <p>Please contact your school administrator to arrange for the subscription to be renewed.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-1"></div>
    </div>
</template>

<script>
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import ContactUsMixin from '@/components/mixins/ContactUsMixin';
import SchoolMixins from '@/components/mixins/SchoolMixins';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import SubscriptionContactForm from '@/components/forms/SubscriptionContactForm';
import Form from '@/components/classes/Form';
import SchoolForm from '@/components/forms/SchoolForm';

export default {
  name: 'SubscriptionProcess',
  mixins: [SubscriptionMixin, ContactUsMixin, SchoolMixins, CurrentUserMixin],
  data() {
    return {
      section: 'Subscription',
      invite: null,
      action: null,
      isLoading: false,
      canManageSubscription: false,
      showForm: true,
      form: new Form({
        time: '10 am',
        contact_number: null,
        email: this.$store.state.emailAddress,
        contact_preference: 'phone',
      }),
      school: this.getDefaultSchool(),
    };
  },
  mounted() {
    this.canManageSubscription = this.$authorization.isAuthorized('manage_subscription');
    this.$apiResponse.showHeading(false);
  },
  created() {
    this.guard();
    if (!this.$store.state.hasPassedSubscriptionScreening) {
      this.getMe()
        .then(() => this.handleSubscriptionProcess())
        .then((path) => {
          if (path !== this.$router.history.current.path) {
            this.$router.replace(path); // to avoid back button
          }
        })
        .catch(() => {
          this.$router.replace('/logout');
        });
    }
  },
  methods: {
    callSendForm(formName) {
      this.form = new Form(this.form);
      this.sendForm(formName);
    },
    createSchool() {
      this.isLoading = true;
      this.$http.post('enterprises/', {
        name: this.school.name,
        urn: this.school.urn,
        type: 'school',
      })
        .then((response) => this.$http.post(`enterprises/${response.data.enterprise.id}/contacts`, { contact: this.flattenContacts(this.school) }))
        .then(() => this.getMe())
        .then(() => {
          this.isLoading = false;
          this.$router.replace('/subscription/start');
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
    guard() {
      this.action = this.$route.params.action;
      if (!this._.includes(['register', 'start', 'renew', 'warning'], this.action)) {
        this.$router.replace('/logout');
        return;
      }
      if (!this._.includes(['register', 'warning'], this.action) && this.$store.state.user.user_type !== 'enterprise' && !this.$route.path === '/subscription/warning') {
        // eslint-disable-next-line consistent-return
        return this.$router.replace('/subscription/warning');
      }
    },
    redirect(url) {
      this.$router.replace(url);
    },
  },
  watch: {
    $route() {
      this.guard();
    },
  },
  components: {
    SubscriptionContactForm,
    SchoolForm,
  },
};
</script>
