export function countryPaysTax(country) {
  if (!country) { return false; }
  return [
    'GBR',
  ].indexOf(country.toUpperCase()) !== -1;
}

export function schoolPaysTax(school, fallbackToPrimary = false) {
  // Need a contacts list
  if (!('contacts' in school)) {
    throw new Error('Cannot get tax settings from school with no contacts');
  }

  // Need a billing contact
  const billingContact = school.contacts.find((c) => c.type === 'billing');
  if (!billingContact && !fallbackToPrimary) {
    throw new Error('Cannot get tax without a billing contact');
  }
  const primaryContact = school.contacts.find((c) => c.type === 'primary');
  if (!primaryContact) {
    throw new Error('Cannot get tax without a billing or primary contact');
  }

  return countryPaysTax(billingContact.country_code);
}
