<template>
  <div v-if="isReady">
    <modal title="" v-if="modal.isVisible('contact')" @close="modal.hide('contact')" :show-footer="false">
      <contact-iframe height="505" />
    </modal>

    <template v-if="displayContactIframe">
      <contact-iframe />
    </template>

    <template v-else-if="currentSubscription && currentSubscription.payment_provider === 'manual'">
      <div class="subscription-options">
      <div class="subscription-package">
          <div class="subscription-package__header">
            <div class="subscription-package__title">Custom Package</div>
            <div class="subscription-package__price" v-html="currentSubscription.payment_amount <= 0 ? 'Complimentary' : `£${currentSubscription.payment_amount}<small>/${currentSubscription.renewal_duration_unit}</small>`"></div>
          </div>
          <div class="subscription-package__body">
            <div class="subscription-package__description">
              <span>Please click the button below, if you wish to extend or change your subscription</span>
            </div>
            <div class="subscription-package__actions">
                <button class="btn btn-block btn-primary" @click.prevent="modal.show('contact')">Discuss</button>
                <span>Expires {{formatDate(currentSubscription.billing_period_end ? currentSubscription.billing_period_end : currentSubscription.expires_at, 'DD MMMM YYYY')}}</span>
              </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div v-if="currentSubscription && currentSubscription.is_trial">
        <template v-if="currentSubscription.has_expired">
          <h2 class="text-center mb-1 page-title">Subscription Expired</h2>
          <p class="mb-2 page-description text-center">Your trial subscription expired on the {{formatDate(currentSubscription.expires_at, 'Do MMMM YYYY')}}.<br />If you would like to continue using Fonetti please select a subscription package below.</p>
        </template>
      </div>
      <div v-if="currentSubscription === null">
        <h2 class="text-center mb-0 page-title">Select a subscription</h2>
        <div class="tax-exclusive-message mb-1">displayed prices are exclusive of VAT</div>
      </div>
      <div class="subscription-options">
        <div class="subscription-package" v-for="availablePackage in availablePackages" :key="availablePackage.id">

          <div class="subscription-package__header">
            <div class="subscription-package__title">{{availablePackage.title}}</div>
            <div class="subscription-package__price" v-html="availablePackage.is_trial ? 'Free' : `£${availablePackage.price}<small>/year</small>`"></div>
          </div>
          <div class="subscription-package__body">

            <div class="subscription-package__description">
              <span v-html="availablePackage.description"></span>
              <!-- <small v-if="availablePackage.max_readers === 999"><br />* subject to fair use policy</small> -->
            </div>

            <template v-if="availablePackage.has_subscription">
              <div class="subscription-package__current">Current</div>
              <div class="subscription-package__actions">
                <button class="btn btn-block btn-primary" v-if="!availablePackage.is_trial && isNearingEndOfSubscription && !hasOpenInvoice" @click="$emit('select', availablePackage.id)">Extend</button>
                <span v-else-if="currentSubscription">Expires {{formatDate(currentSubscription.billing_period_end ? currentSubscription.billing_period_end : currentSubscription.expires_at, 'Do MMMM YYYY')}}</span>
              </div>
            </template>
            <template v-else>
              <div class="subscription-package__student-limit">{{ availablePackage.max_readers === 999 ? 'Unlimited students' : `Up to ${availablePackage.max_readers} students` }}</div>
              <div class="subscription-package__actions">
                <!-- If current subscription is higher, or there's an open invoice, disable upgrading --->
                <template v-if="currentSubscription && currentSubscription.max_readers > availablePackage.max_readers">
                  <small class="upgrade-unavailable-message">Downgrading is currently unsupported. Contact us for assistance.</small>
                </template>
                <template v-else-if="hasOpenInvoice">
                  <small class="upgrade-unavailable-message">Upgrading is not available whilst there is an unpaid invoice. Contact us for assistance.</small>
                </template>
                <template v-else>
                  <button class="btn btn-primary btn-block" @click="$emit('select', availablePackage.is_trial ? 'trial' : availablePackage.id)">
                    {{ (currentSubscription && !currentSubscription.has_expired) || isOutOfTrialPeriod ? 'Upgrade' : 'Subscribe' }}
                  </button>
                </template>
              </div>
            </template>

          </div>
        </div>
        <slot name="invoices"></slot>
      </div>

      <div class="custom-subscription-prompt mt-3">
        <span>Want to discuss a multi-year or multi-school subscription?</span>
        <button class="btn btn-primary" @click="modal.show('contact')">Contact Sales</button>
      </div>
    </template>

  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import Utils from '@/components/mixins/Utils';
import Modal from '@/components/classes/Modal';
import ModalJS from '@/components/utils/Modal';
import ContactIframe from '@/components/partials/ContactIframe';

export default {
  name: 'subscription-selector',
  components: {
    ContactIframe,
    modal: ModalJS,
  },
  props: {
    schoolId: {
      required: true,
    },
    isNew: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  mixins: [Utils],
  data() {
    return {
      modal: new Modal({
        contact: false,
      }),
      availablePackages: [],
      currentSubscription: null,
      isReady: false,
    };
  },
  mounted() {
    this.$store.commit('SET_SELECTED_SUBSCRIPTION_PACKAGE_ID', null);
    this.$http.get(`/enterprises/${this.schoolId}/subscriptions/packages`)
      .then((response) => {
        this.availablePackages = this.formatPackages(response.data.packages);
      });
    this.$http.get(`/enterprises/${this.schoolId}/subscriptions`)
      .then((response) => {
        this.currentSubscription = response.data.subscription;
        this.isReady = true;
      });
  },
  methods: {
    remainingDays(expiresAt) {
      const endDate = moment(expiresAt);
      const today = moment();
      const remainingDays = endDate.diff(today, 'days');
      if (remainingDays <= 0) {
        return 'today';
      }

      return `in ${remainingDays} days`;
    },
    formatPackages(availablePackages) {
      return availablePackages.map(((availablePackage) => {
        availablePackage.price = Math.round(availablePackage.price);
        return availablePackage;
      }));
    },
  },
  computed: {
    isOutOfTrialPeriod() {
      return this.currentSubscription && this.currentSubscription.is_trial && this.currentSubscription.has_expired;
    },
    displayContactIframe() {
      return this.isNew && !this.isUserInUK();
    },
    ...mapState([
      'isNearingEndOfSubscription',
      'hasOpenInvoice',
    ]),
  },
};
</script>

<style lang="scss" scoped>
#app .box-front button.btn {
  margin: 0;
}
.page-title {
  color: white;
  font-weight: 900;
  text-shadow: 1px 1px 0 #00000044;
}
.page-description {
  color: white;
  text-shadow: 1px 1px 0 #00000044;
  font-size: 1.2em;
}
.custom-subscription-prompt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fc8401;
  padding: 0.6em;
  border-radius: 50px;
  > span {
    font-size: 1.1em;
    font-weight: 600;
    color: #fffdfa;
    padding-left: 0.5em;
  }
}
.subscription-options {
  display: flex;
  gap: 30px;
  justify-content: center;
  > * {
     flex: 1 1;
  }
}
.subscription-package {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width:300px;

  &__header, &__body {
    padding: 10px;
    position:relative;
  }

  &__header {
    filter: drop-shadow(0px 2px 1px #00000044);
  }

  &__body {
    flex: 1;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
  }

  &__price {

    font-size: 1.6em;
    padding: 0.1em 1em;
    position:absolute;
    z-index: 1;
    background: var(--accent-color);
    margin: 0 auto;
    left: 0; right: 0;
    width: min-content;
    bottom:0;
    transform: translateY(50%);
    border-radius: 1em;
    > /deep/ small {
      margin-left: 0.1em;
      font-size: 0.7em;
    }
  }

  &__current {
    font-size: 26px;
  }

  &__description {
    font-size: 1.1em;
    line-height: 1.15em;
    margin-bottom: 1em;
    flex: 1 1;
    small {
      font-style: italic;
      font-size: 0.75em;
      color: #555;
    }
  }

  &__student-limit {
    margin-top: 20px;
  }

  &__actions {
    button {
      margin-top: 10px;
      background-color: var(--accent-color);
    }
  }

  // These styles apply to guest style pages

  background: white;
  border-radius: 15px;
  box-shadow: 0 2px 2px #00000033;

  &__title {
    font-size: 1.8em;
    font-weight: 700;
    margin-bottom:0.4em;
  }

  &__header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: var(--accent-color);
    color: rgba(255,255,255,0.95);
  }
  &__body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  // Accents
  &:nth-child(1) {
    --accent-color: #646669;
  }
  &:nth-child(2) {
    --accent-color: #009445;
  }
  &:nth-child(3) {
    --accent-color: #2b4462;
  }

  //border-top: 3px solid red;

}
.custom-subscription-prompt {
  box-shadow: 0 2px 2px #00000033;
  color: white;
  .btn {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.2px;
    font-size: 0.9em;
    background: #fc8401;
    box-shadow: inset 0px 0px 0px 2px white;
    transition: box-shadow 0.25s linear;
    &:hover {
      box-shadow: inset 0px 0px 0px 30px white;
      color: #fc8401
    }
  }
}

.upgrade-unavailable-message {
  font-size: 0.9em;
  font-style: italic;
  line-height: 1.2em;
  display: block;
  margin-top: 1em;
}

.tax-exclusive-message {
  color: white;
  font-weight: 400;
  text-align: center;
  font-style: italic;
}
</style>
