<template>
  <div class="container">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="register-form box-front">
              <img src="../assets/img/fonetti-logo.png" class="center-block logo">
              <div class="box-header">
                <h3 class="box-title">Please confirm you have read and understood the following:</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-md-8 col-centered mt-1">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="observer" class="ui form form-horizontal" @submit.prevent="handleSubmit(sendAcceptEula)">
                    <ValidationProvider :rules="{required: { allowFalse: false }}" name="Accept EULA" v-slot="{ errors }">
                      <div class="form-group form-checkbox" :class="{'has-error': errors.length > 0}">
                        <label for="accept-eula" class="control-label">
                          <input name="accept-eula" id="accept-eula" type="checkbox" v-model="form.acceptEULA">
                          I agree to the <a href="https://www.fonetti.com/wp-content/uploads/2021/02/School-EULA-v1.pdf" target="_blank" rel="noopener noreferrer" alt="opens in new window">End User License Agreement</a>
                        </label>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider :rules="{required: { allowFalse: false }}" name="Accepts Ts and Cs" v-slot="{ errors }">
                      <div class="form-group form-checkbox" :class="{'has-error': errors.length > 0}">
                        <label for="accept-terms-and-conditions" class="control-label">
                          <input name="accept-terms-and-conditions" id="accept-terms-and-conditions" type="checkbox" v-model="form.acceptTermsAndConditions">
                          I agree to the <a href="https://www.fonetti.com/terms-of-use/" target="_blank" rel="noopener noreferrer" alt="opens in new window">Terms &amp; Conditions</a> and have read the <a href="https://www.fonetti.com/privacy-policy/" target="_blank" alt="opens in new window">Privacy Policy</a>
                        </label>
                      </div>
                    </ValidationProvider>
                    <div class="form-group">
                      <div class="col-centered col-md-10 text-center">
                        <loading-button :is-loading="loading">Submit</loading-button>
                      </div>
                    </div>
                    </form>
                </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
  </div>
</template>

<script>
import FormMixin from '@/components/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';
import UserMixin from '@/components/mixins/CurrentUserMixins';

export default {
  name: 'confirm-eula',
  mixins: [FormMixin, UserMixin],
  components: { LoadingButton },
  beforeMount() {
    if (this.hasAlreadyAcceptedEULA) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        acceptEULA: false,
        acceptTermsAndConditions: false,
      },
    };
  },
  methods: {
    sendAcceptEula() {
      this.$http.post(`enterprises/${this.$store.state.user.enterprise_id}/terms/accept`)
        .then(() => {
          this.getMe()
            .then(() => {
              this.$router.push('/');
            });
        })
        .catch((error) => {
          this.flashMessage(error, 'error');
        });
    },
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    hasAlreadyAcceptedEULA() {
      return !!this.$store.state.user.enterprise.terms_accepted_at;
    },
  },
};
</script>
