<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form data-testid="account-form" ref="observer" class="ui form form-horizontal" @submit.prevent="handleSubmit(validateForm)">
      <ValidationProvider ref="firstNameProvider" :rules="{ required: true, min: 2 }" name="First Name" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="first_name" class="col-sm-2 control-label">First Name</label>
          <div class="col-sm-10">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
              <input data-testid="first-name" class="form-control" id="first_name" name="first_name" placeholder="First name" type="text" v-model="mutableValue.first_name">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider ref="lastNameProvider" :rules="{ required: true, min: 2 }" name="Last Name" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="last_name" class="col-sm-2 control-label">Last Name</label>
          <div class="col-sm-10">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
              <input data-testid="last-name" class="form-control" id="last_name" name="last_name" placeholder="Last name" type="text" v-model="mutableValue.last_name">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider ref="emailProvider" rules="required|email" name="Email" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="email" class="col-sm-2 control-label">Email</label>
          <div class="col-sm-10">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
              <input data-testid="email" class="form-control" id="email" name="email" placeholder="Email" type="email" v-model="mutableValue.email">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider ref="passwordProvider" :rules="{ min: 7 }" name="Password" v-slot="{ errors }">
        <div class="form-group" :class="{ 'has-error': errors[0] }">
          <label for="password" class="col-sm-2 control-label">Password</label>
          <div class="col-sm-10">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-lock"></i></span>
              <input data-testid="password" class="form-control" id="password" name="password" placeholder="Password" type="password" v-model="mutableValue.password">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="form-group checkbox">
        <div class="col-sm-offset-2">
          <input id="weekly_school_digest_optin" name="weekly_school_digest_optin" type="checkbox" class="checkbox-v2 em2" :value="value.weekly_school_digest_optin" :checked="value.weekly_school_digest_optin" @input="update('weekly_school_digest_optin', $event.target.checked)">
          <label for="weekly_school_digest_optin" class="form-guest__label form-guest__label--small">I want to subscribe to the weekly digest</label>
        </div>
      </div>

      <div class="form-group mt-1">
        <div class="col-sm-offset-2 col-sm-10">
          <loading-button :is-loading="loading">Submit</loading-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '../utils/LoadingButton';
import FormMixin from '../mixins/FormMixin';

export default {
  name: 'account-form',
  props: ['value', 'isLoading'],
  mixins: [FormMixin],
  components: { LoadingButton },
  computed: {
    loading() {
      return this.isLoading;
    },
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.form-group.checkbox {
  margin-left: 25px;
}
</style>
