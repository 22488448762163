<template>
  <div class="invoices-component">
    <!-- Modals -->
    <modal title="" v-if="modal.isVisible('refresh')" @close="modal.hide('refresh')">
      <p>Once you have completed payment, please wait a few seconds then refresh the page to see your updated subscription status.</p>
      <button class="btn btn-primary center-block" @click="refreshPage">Refresh my subscription</button>
    </modal>
    <!-- Content -->
    <table v-if="invoices.length > 0" class="table invoices-table">
      <thead>
        <tr>
          <th>Date issued</th>
          <th>Status</th>
          <th>Due Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>{{formatDate(invoice.issued_at)}}</td>
          <td>
            <span v-if="isProcessingPayment && invoice.id === currentSubscriptionInvoiceId" class="label label-danger">processing</span>
            <span v-else :class="'label label-'+invoiceStatusLabelClass(invoice.status)">{{invoiceStatusText(invoice.status)}}</span>
          </td>
          <td>{{formatDate(invoice.due_date)}}</td>
          <td class="actions">
            <a :href="invoice.invoice_pdf" target="_blank" data-toggle="tooltip" data-placement="top" title="Download this invoice"><i class="fa fa-download"></i></a>
            <a v-if="invoice.status === 'open'" :href="invoice.hosted_invoice_url" target="_blank" data-toggle="tooltip" data-placement="top" title="Pay this invoice" @click="modal.show('refresh')"><i class="fa fa-credit-card"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Utils from '@/components/mixins/Utils';
import ModalJS from '@/components/utils/Modal';
import Modal from '@/components/classes/Modal';

export default {
  name: 'invoices',
  components: {
    modal: ModalJS,
  },
  mixins: [Utils],
  data() {
    return {
      modal: new Modal({
        refresh: false,
      }),
    };
  },
  mounted() {
    const hasOutstandingInvoice = this.invoicesMeta && this.invoicesMeta.has_outstanding_invoice;
    if (hasOutstandingInvoice) {
      this.$store.commit('SET_INVOICE', this.invoicesMeta.outstanding_invoice);
      this.$router.push({ path: '/payment-required', query: { show_sp_link: false } });
    }
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    invoiceStatusText(status) {
      switch (status) {
        case 'open':
          return 'unpaid';
        default:
          return status;
      }
    },
    invoiceStatusLabelClass(status) {
      switch (status) {
        case 'paid':
          return 'success';
        case 'open':
          return 'warning';
        case 'uncollectible':
          return 'danger';
        default: // draft / void / deleted
          return 'default';
      }
    },
  },
  computed: {
    ...mapGetters([
      'invoices',
      'invoicesMeta',
      'isProcessingPayment',
      'currentSubscriptionInvoiceId',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.invoices-component {
  flex: 0 0 auto;
  margin-left: auto;
}

table.invoices-table {
  whitespace: nowrap;
  filter: drop-shadow(0px 2px 1px #00000044);

  thead {
    td,th { background: #efefef; color: #666;}
    tr:first-child {
      td,th:first-child { border-top-left-radius: 15px; }
      td,th:last-child { border-top-right-radius: 15px; }
    }
  }
  tbody {
    td { background: white; }
    tr:last-child {
      > :first-child { border-bottom-left-radius: 15px; }
      > :last-child { border-bottom-right-radius: 15px; }
    }
  }

  td, th {
    padding: 0.75em;
  }

  .actions {
    display: flex;
    gap: 20px;
  }
}
</style>
