<template>
  <div class="container">
    <div class="row">
      <div class="col-centered col-md-9">
        <div class="setup-form box-front">
          <h2 class="mb-1">Confirm billing details</h2>
          <action-messages></action-messages>
          <div class="split-view" v-if="selectedPackage">
            <div class="col-xs-12">
              <payment-details-form v-model="form" @validated="setupSubscription" />
            </div>
            <div class="info-panel">
              <p>An invoice will be generated for the sum of <strong>£{{price}}<span v-if="taxable">+VAT</span></strong> granting you full access to the Fonetti School Portal and associated platforms/services with <strong><template v-if="selectedPackage.max_readers === 999">unlimited students</template><template v-else>up to {{selectedPackage.max_readers}} students</template></strong> for 12 months. Payment is required <strong>within 30 days</strong> otherwise your account will be locked until payment is received.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentDetailsForm from '@/components/forms/PaymentDetailsForm';
import { countryPaysTax } from '@/utils/tax';

export default {
  name: 'confirm-subscription',
  components: {
    PaymentDetailsForm,
  },
  data() {
    return {
      form: {
        contact: {
          email: this.$store.state.user.email,
          address_line_1: null,
          address_line_2: null,
          address_line_3: null,
          address_line_4: null,
          postcode: null,
          country_code: null,
          telephone: null,
          contact_name: this.$store.state.user.full_name,
        },
        po_number: null,
      },
      selectedPackage: null,
      billingAddress: null,
      selectedPackageId: null,
      meta: null,
    };
  },
  mounted() {
    if (!this.$authorization.isAuthorized('manage_subscription')) {
      this.$router.push('/');
    }
    this.selectedPackageId = this.$store.state.selectedSubscriptionPackageId;
    if (this.selectedPackageId === null) {
      this.$router.push('/');
      return;
    }
    this.$http.get(`/enterprises/${this.$store.state.user.enterprise.id}/packages/${this.selectedPackageId}`)
      .then((response) => {
        this.selectedPackage = response.data.package;
        this.meta = response.data.meta;
      })
      .catch((err) => this.$apiResponse.renderErrorMessage(err));
    this.$http.get(`/enterprises/${this.$store.state.user.enterprise.id}/contacts/billing`)
      .then((response) => {
        this.billingAddress = response.data.billing_address;
        this.form.contact.address_line_1 = this.billingAddress.address_line_1;
        this.form.contact.address_line_2 = this.billingAddress.address_line_2;
        this.form.contact.address_line_3 = this.billingAddress.address_line_3;
        this.form.contact.address_line_4 = this.billingAddress.address_line_4;
        this.form.contact.postcode = this.billingAddress.postcode;
        this.form.contact.telephone = this.billingAddress.telephone;
        this.form.contact.country_code = this.billingAddress.country_code;
      })
      .catch((err) => this.$apiResponse.renderErrorMessage(err));
  },
  methods: {
    setupSubscription() {
      this.$store.commit('IS_LOADING', true);
      this.form.package_id = this.selectedPackageId;
      this.$http.post(`enterprises/${this.$store.state.user.enterprise.id}/subscriptions`, this.form)
        .then((response) => {
          const { invoice } = response.data;
          this.$store.commit('SET_INVOICE', invoice);
          this.$router.push({ name: 'feedback-subscription', params: { contact_email: this.form.contact.email } });
        })
        .catch((error) => {
          this.$apiResponse.renderErrorMessage(error);
        })
        .finally(() => this.$store.commit('IS_LOADING', false));
    },
  },
  computed: {
    price() {
      if (this.meta && this.meta.is_upgrading) {
        return this.meta.prorated_price;
      }

      return this.selectedPackage.price;
    },
    taxable() {
      return countryPaysTax(this.form.contact.country_code);
    },
  },
};
</script>

<style lang="scss" scoped>
.split-view {
  display: flex;
  gap: 30px;
  > * {
    flex: 1 1;
  }
}
</style>
