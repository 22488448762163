<template>
  <div class="container container-table">
    <div class="row">
      <div class="col-md-3 col-sm-1"></div>
      <div class="col-md-6 col-sm-10">
        <div class="row">
          <div class="col-centered col-md-10">
            <div class="login-form box-front">
              <img src="../assets/img/fonetti-logo.png" class="center-block logo">
              <div class="text-center">
                <h2 class="text-bold">Page not found</h2>
                <button class="btn" @click="$router.push('/')">Go to main page</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-1"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
h2 {
  color: black;
}
</style>
