export default {
  data() {
    return {
    };
  },
  methods: {
    getSchool(schoolId, includes = '') {
      return new Promise((resolve, reject) => {
        this.$http.get(`enterprises/${schoolId}?include=${includes}`)
          .then((response) => {
            const school = response.data.enterprise;
            this.$store.commit('SET_CURRENT_SCHOOL', school);
            this.$store.dispatch('handleNullOrExpiredSubscription', { router: this.$router, subscription: school.subscription });
            if (school.subscription && school.subscription.meta) {
              this.$store.dispatch('handleSubscriptionMeta', { meta: school.subscription.meta });
            }
            if (school.invoices) {
              this.$store.dispatch('handleOpenInvoice', { router: this.$router, meta: school.invoices.meta });
            }
            resolve(school);
          })
          .catch((error) => {
            this.$apiResponse.renderErrorMessage(error);
            reject(error);
          });
      });
    },
    formatSchool(school) {
      const formattedSchool = {};
      formattedSchool.id = school.id;
      formattedSchool.name = school.name;
      formattedSchool.urn = school.urn;
      formattedSchool.recording_enabled = school.recording_enabled;
      formattedSchool.can_enable_recordings = school.can_enable_recordings;

      // eslint-disable-next-line
      if (_.isEmpty(school.contacts)) {
        formattedSchool.primaryContact = this.formatEmptyContact();
        formattedSchool.billingContact = this.formatEmptyContact();
      } else {
        // eslint-disable-next-line
        _.forEach(school.contacts, contact => {
          let key = null;
          switch (contact.type) {
            case 'billing':
              key = 'billingContact';
              break;
            default:
              key = 'primaryContact';
              break;
          }
          formattedSchool[key] = this.formatContact(contact);
        });
      }

      formattedSchool.billingSameAsPrimary = this.checkIfBillingIsSameAsPrimary(formattedSchool);

      return formattedSchool;
    },
    formatContact(contact) {
      const formattedContact = {};
      formattedContact.id = contact.id;
      formattedContact.name = contact.contact_name;
      formattedContact.job_role = contact.job_role;
      formattedContact.address = {};
      formattedContact.address.line1 = contact.address_line_1;
      formattedContact.address.line2 = contact.address_line_2;
      formattedContact.address.line3 = contact.address_line_3;
      formattedContact.address.line4 = contact.address_line_4;
      formattedContact.address.postcode = contact.postcode;
      formattedContact.address.countryCode = contact.country_code;
      formattedContact.phone = contact.telephone;
      formattedContact.email = contact.email;

      return formattedContact;
    },
    formatEmptyContact() {
      const formattedContact = {};
      formattedContact.id = null;
      formattedContact.name = null;
      formattedContact.address = {};
      formattedContact.address.line1 = null;
      formattedContact.address.line2 = null;
      formattedContact.address.line3 = null;
      formattedContact.address.line4 = null;
      formattedContact.address.postcode = null;
      formattedContact.address.countryCode = null;
      formattedContact.phone = null;
      formattedContact.email = null;

      return formattedContact;
    },
    flattenContacts(school) {
      const flatContacts = [];
      flatContacts.push(this.flattenContact(school.primaryContact, 'primary'));
      if (!this.school.billingSameAsPrimary) {
        flatContacts.push(this.flattenContact(school.billingContact, 'billing'));
      } else { // else we duplicate the primary contact as the billing one
        school.primaryContact.id = school.billingContact.id;
        flatContacts.push(this.flattenContact(school.primaryContact, 'billing'));
      }

      return flatContacts;
    },
    flattenContact(contact, type) {
      return {
        id: contact.id,
        type,
        contact_name: contact.name,
        address_line_1: contact.address.line1,
        address_line_2: contact.address.line2,
        address_line_3: contact.address.line3,
        address_line_4: contact.address.line4,
        postcode: contact.address.postcode,
        country_code: contact.address.countryCode,
        telephone: contact.phone,
        email: contact.email,
      };
    },
    checkIfBillingIsSameAsPrimary(school) {
      const billingContact = this._.cloneDeep(school.billingContact);
      delete billingContact.id;
      const primaryContact = this._.cloneDeep(school.primaryContact);
      delete primaryContact.id;

      return this._.isEqual(billingContact, primaryContact);
    },
    extractGroupsFromSchool(school) {
      const groups = [];
      this._.each(school.groups, (group) => {
        groups.push(group.name);
      });
      return this._.uniq(groups);
    },
    getDefaultSchool() {
      return {
        name: '',
        urn: '',
        primaryContact: {
          name: this.$store.state.user ? `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}` : '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            line4: '',
            postcode: '',
            countryCode: 'GBR',
          },
          phone: '',
          email: this.$store.state.user ? this.$store.state.user.email : '',
        },
        billingContact: {
          name: '',
          address: {
            line1: '',
            line2: '',
            line3: '',
            line4: '',
            postcode: '',
            countryCode: 'GBR',
          },
          phone: '',
          email: '',
        },
        billingSameAsPrimary: true,
      };
    },
  },
};
