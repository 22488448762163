<template>
  <ValidationObserver v-slot="{ passes }">
    <form ref="observer" class="ui form schoolForm form-horizontal" @submit.prevent="passes(validateForm)">
      <fieldset>
        <legend>Information</legend>
        <!-- Name form input -->
        <ValidationProvider rules="required" name="Name" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label for="name" class="col-sm-2 control-label" v-if="!deleteLabel">Name</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-user"></i></span>
                <input class="form-control" id="name" name="name" placeholder="Name" type="text" :value="value.name" @input="update('name', $event.target.value)">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- URN form input -->
        <div class="form-group">
          <label for="urn" class="col-sm-2 control-label" v-if="!deleteLabel">URN</label>
          <div :class="inputWidth">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-user"></i></span>
              <input class="form-control" id="urn" name="urn" placeholder="URN" type="text" :value="value.urn" @input="update('urn', $event.target.value)">
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Primary Contact</legend>
        <!-- Contact Name form input -->
        <contact-form :value="value.primaryContact" @input="update('primaryContact', $event.target.value)" :delete-label="deleteLabel" :input-width="inputWidth" prefix="primary_"></contact-form>
      </fieldset>
      <fieldset>
        <legend>Billing Address</legend>
        <!-- Billing same as primary form checkbox -->
        <div class="form-group form-checkbox">
          <label class="control-label">
            <input type="checkbox" :value="value.billingSameAsPrimary" :checked="!!value.billingSameAsPrimary" @input="update('billingSameAsPrimary', $event.target.checked)">
            Billing address same as primary contact
          </label>
        </div>
        <template v-if="!value.billingSameAsPrimary">
          <contact-form :value="value.billingContact" @input="update('billingContact', $event.target.value)" :delete-label="deleteLabel" :input-width="inputWidth" prefix="billing_"></contact-form>
        </template>
      </fieldset>
      <div :class="origin === 'register' ? '' : 'box-footer no-border'">
        <div class="form-group">
          <div :class="origin === 'register' ? '' : 'text-center'">
            <loading-button :is-loading="isLoading" :class="origin === 'register' ? 'btn-block' : ''">{{origin === 'confirmDetails' ? 'Save and Continue' : 'Submit' }}</loading-button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import LoadingButton from '@/components/utils/LoadingButton';
import FormMixin from '@/components/mixins/FormMixin';
import ContactForm from '@/components/forms/ContactForm';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'SchoolForm',
  mixins: [FormMixin],
  props: {
    value: {
    },
    origin: {
      default: '',
    },
    isLoading: {
      default: false,
    },
  },
  data() {
    return {
      countries,
    };
  },
  computed: {
    deleteLabel() {
      return this.origin === 'register';
    },
    inputWidth() {
      return this.origin === 'register' ? 'col-sm-12' : 'col-sm-10';
    },
  },
  components: {
    ContactForm,
    LoadingButton,
    'loading-button': LoadingButton,
  },
};
</script>

<style>
  .schoolForm > fieldset {
    margin-bottom: 40px;
  }
  .schoolForm select {
    border: none;
    padding-right: 20px;
    border-bottom: 1px solid #272f30;
  }
  .form-horizontal .form-checkbox {
    margin-left: 5px;
  }
</style>
