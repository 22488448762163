<template>
  <ValidationObserver>
    <div class="contact-form" data-testid="contact-form" @submit.prevent="passes(validateForm(mutableValue))">
        <!-- Contact Name form input -->
        <ValidationProvider ref="nameProvider" rules="required" name="Contact Name" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('contact_name')" class="col-sm-2 control-label" v-if="!deleteLabel">Contact Name</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-user"></i></span>
                <input data-testid="name" class="form-control" :id="fieldPrefix('contact_name')" :name="fieldPrefix('contact_name')" placeholder="Contact Name" type="text" v-model="mutableValue.name">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Job Role form input -->
        <ValidationProvider ref="roleProvider" :rules="{required: false}" name="Job Role" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('contact_name')" class="col-sm-2 control-label" v-if="!deleteLabel">Job Role</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-user"></i></span>
                <input data-testid="job-role" class="form-control" :id="fieldPrefix('job_role')" :name="fieldPrefix('job_role')" placeholder="Job Role" type="text" v-model="mutableValue.job_role">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Address Line 1 form input -->
        <ValidationProvider ref="addressProvider" rules="required" name="Address Line 1" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('address_line_1')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 1</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-house"></i></span>
                <input data-testid="address-line-1" class="form-control" :id="fieldPrefix('address_line_1')" :name="fieldPrefix('address_line_1')" placeholder="Address Line 1" type="text" v-model="mutableValue.address.line1">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Address Line 2 form input -->
        <div class="form-group">
          <label :for="fieldPrefix('address_line_2')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 2</label>
          <div :class="inputWidth">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-house"></i></span>
              <input data-testid="address-line-2" class="form-control" :id="fieldPrefix('address_line_2')" :name="fieldPrefix('address_line_2')" placeholder="Address Line 2" type="text" v-model="mutableValue.address.line2">
            </div>
          </div>
        </div>
        <!-- Address Line 3 form input -->
        <div class="form-group">
          <label :for="fieldPrefix('address_line_3')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 3</label>
          <div :class="inputWidth">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-house"></i></span>
              <input data-testid="address-line-3" class="form-control" :id="fieldPrefix('address_line_3')" :name="fieldPrefix('address_line_3')" placeholder="Address Line 3" type="text" v-model="mutableValue.address.line3">
            </div>
          </div>
        </div>
        <!-- Address Line 4 form input -->
        <div class="form-group">
          <label :for="fieldPrefix('address_line_4')" class="col-sm-2 control-label" v-if="!deleteLabel">Address Line 4</label>
          <div :class="inputWidth">
            <div class="input-group">
              <span class="input-group-addon"><i class="icon icon-house"></i></span>
              <input data-testid="address-line-4" class="form-control" :id="fieldPrefix('address_line_4')" :name="fieldPrefix('address_line_4')" placeholder="Address Line 4" type="text" v-model="mutableValue.address.line4">
            </div>
          </div>
        </div>
        <!-- Postcode form input -->
        <ValidationProvider ref="postcodeProvider" rules="required|postcode" name="Postcode" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('postcode')" class="col-sm-2 control-label" v-if="!deleteLabel">Postcode</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-house"></i></span>
                <input data-testid="postcode" class="form-control" :id="fieldPrefix('postcode')" :name="fieldPrefix('postcode')" placeholder="Postcode" type="text" v-model="mutableValue.address.postcode">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Country Code form select -->
        <ValidationProvider ref="countryProvider" :rules="{ required: true }" name="Country" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('country')" class="col-sm-2 control-label" v-if="!deleteLabel">Country</label>
            <div :class="inputWidth">
              <div class="input-group">
                <select data-testid="country-code" class="form-control" name="country"
                        v-model="mutableValue.address.countryCode">
                  <option v-for="(name, key) in countries.getNames('en')" :value="countries.alpha2ToAlpha3(key)"
                          :selected="countries.alpha2ToAlpha3(key) === value" :key="key">{{ Array.isArray(name) ? `${name[0]} (${name[1]})` : name }}
                  </option>
                </select>
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Phone form input -->
        <ValidationProvider ref="phoneProvider" :rules="{ required: true, regex: /^[+0-9]+$/ }" name="Phone" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('phone')" class="col-sm-2 control-label" v-if="!deleteLabel">Phone</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-phone"></i></span>
                <input data-testid="phone" class="form-control" :name="fieldPrefix('phone')" :id="fieldPrefix('phone')" placeholder="Phone" type="text" v-model="mutableValue.phone">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
        <!-- Email form input -->
        <ValidationProvider ref="emailProvider" rules="required|email" name="Email" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <label :for="fieldPrefix('email')" class="col-sm-2 control-label" v-if="!deleteLabel">Email</label>
            <div :class="inputWidth">
              <div class="input-group">
                <span class="input-group-addon"><i class="icon icon-envelope"></i></span>
                <input data-testid="email" class="form-control" :id="fieldPrefix('email')" :name="fieldPrefix('email')" placeholder="Email"
                      type="email" v-model="mutableValue.email">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'contact-form',
  props: ['value', 'prefix', 'deleteLabel', 'inputWidth'],
  data() {
    return {
      countries,
      mutableValue: Object.assign(this.value),
    };
  },
  methods: {
    fieldPrefix(value) {
      if (_.isEmpty(this.prefix)) {
        return value;
      }

      return `${this.prefix}_${value}`;
    },
  },
};
</script>
