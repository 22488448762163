import moment from 'moment';

export default {
  methods: {
    formatDate(date, format = 'DD MMMM \'YY') {
      return moment(date).format(format);
    },
    getUserCountry() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    isUserInUK() {
      const userCountry = this.getUserCountry();
      return userCountry === 'Europe/London';
    },
  },
  computed: {
    canAccessNewStats() {
      // 15 = Paulton Junior School
      return this.getUserSchool().name.indexOf('Fonetti') !== -1 || [15].includes(this.getUserSchool().id);
    },
  },
};
