<template>
  <ValidationObserver v-slot="{ passes }" slim>
    <form ref="observer" class="ui form schoolForm form-horizontal" @submit.prevent="passes(validateForm)">
      <fieldset>
        <ValidationProvider rules="required" name="Name" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="contact_name" name="contact_name" placeholder="Name" type="text" v-model="mutableValue.contact.contact_name">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="email" name="email" placeholder="Email" type="text" v-model="mutableValue.contact.email">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" name="Address Line 1" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="address_line_1" name="address_line_1" placeholder="Address Line 1" type="text" v-model="mutableValue.contact.address_line_1">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_2" name="address_line_2" placeholder="Address Line 2" type="text" v-model="mutableValue.contact.address_line_2">
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_3" name="address_line_3" placeholder="Address Line 3" type="text" v-model="mutableValue.contact.address_line_3">
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_4" name="address_line_4" placeholder="Address Line 4" type="text" v-model="mutableValue.contact.address_line_4">
          </div>
        </div>
        <div class="flex flex-gap-50">
          <ValidationProvider rules="required" name="Postcode" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
              <div class="input-group">
                <input class="form-control" id="postcode" name="postcode" placeholder="Post Code" type="text" v-model="mutableValue.contact.postcode">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider ref="countryProvider" :rules="{ required: true }" name="Country" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
              <div class="input-group">
                <select data-testid="country-code" class="country-select form-control" name="country"
                        v-model="mutableValue.contact.country_code">
                  <option v-for="(name, key) in countries.getNames('en')" :value="countries.alpha2ToAlpha3(key)"
                          :selected="countries.alpha2ToAlpha3(key) === value" :key="key">{{ Array.isArray(name) ? `${name[0]} (${name[1]})` : name }}
                  </option>
                </select>
                <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <ValidationProvider ref="phoneProvider" :rules="{ required: true, regex: /^[+0-9]+$/ }" name="Phone" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input data-testid="phone" class="form-control" name="phone" id="phone" placeholder="Phone" type="text" v-model="mutableValue.contact.telephone">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="po_number" name="po_number" placeholder="Optional Purchase Order Number" type="text" v-model="mutableValue.po_number">
          </div>
        </div>
      </fieldset>
      <loading-button class="btn btn-primary btn-block" :is-loading="isLoading">Continue</loading-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import FormMixin from '@/components/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'payment-details-form',
  components: { LoadingButton },
  mixins: [FormMixin],
  props: ['value'],
  data() {
    return {
      countries,
    };
  },
  computed: {
    ...mapState([
      'isLoading',
    ]),
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
