import Vue from 'vue';
import Router from 'vue-router';
import DashView from '@/views/Dash';
import LoginView from '@/views/Login';
import LoginAsView from '@/views/LoginAs';
import NotFoundView from '@/views/404';
import LogoutView from '@/views/Logout';
import RegisterView from '@/views/Register';
import ForgottenView from '@/views/Forgotten';
import InviteView from '@/views/Invite';
import SubscriptionProcessView from '@/views/SubscriptionProcess';
import ResetPasswordView from '@/views/ResetPassword';
import RegisterSchoolView from '@/views/RegisterSchool';
import NewSubscriptionView from '@/views/Subscriptions/NewSubscription';
import ConfirmSubscriptionView from '@/views/Subscriptions/ConfirmSubscription';
import FeedbackSubscriptionView from '@/views/Subscriptions/FeedbackSubscription';
import store from '@/store';

// Import Views - Dash
import DashboardView from '@/views/Dashboard';
import MySubscriptionView from '@/views/Subscriptions/MySubscription';
import AccountView from '@/views/Account';
import SchoolView from '@/views/School';
import EditSchoolView from '@/views/EditSchool';
import TeachersView from '@/views/Teachers';
import AddTeacherView from '@/views/AddTeacher';
import ClassesView from '@/views/Classes';
import ClassView from '@/views/Class';
import AddPupilView from '@/views/AddPupil';
import SchoolPupilsView from '@/views/SchoolPupils';
import BooksView from '@/views/Books';
import PupilView from '@/views/Pupil';
import PupilBookInfo from '@/views/PupilBookInfo';
import PupilBooks from '@/views/PupilBooks';
import SetupView from '@/views/Setup';
import SetupToken from '@/views/SetupToken';
import ConfirmEula from '@/views/ConfirmEula';
import ConfirmDetails from '@/views/ConfirmDetails';
import Overview from '@/views/Overview';
import UserGuide from '@/views/UserGuide';
import VideoGuides from '@/views/VideoGuides';
import Templates from '@/views/Templates';
import FAQs from '@/views/FAQs';
import Contact from '@/views/Contact';

Vue.use(Router);

const isNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    return;
  }
  const query = { ...to.query };
  next({ name: 'subscriptions', query });
};

const clearLocalStorage = (to, from, next) => {
  store.commit('SET_USER', null);
  store.commit('SET_TOKEN', null);
  store.commit('ACCEPT_TERMS_AND_CONDITIONS', false);

  if (window.localStorage) {
    window.localStorage.setItem('user', null);
    window.localStorage.setItem('token', null);
  }
  next();
};

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: LoginView,
      name: 'Login',
      meta: { guestsOnly: true },
      beforeEnter: clearLocalStorage,
    },
    {
      path: '/register-school',
      component: RegisterSchoolView,
      name: 'Register School',
      meta: { guestsOnly: true },
      beforeEnter: clearLocalStorage,
    },
    {
      path: '/select-subscription',
      component: NewSubscriptionView,
      name: 'Select Subscription',
      meta: { requiresAuth: true },
    },
    {
      path: '/fill-billing-details',
      component: ConfirmSubscriptionView,
      name: 'Fill Billing Details',
      meta: { requiresAuth: true },
    },
    {
      path: '/thanks',
      component: FeedbackSubscriptionView,
      name: 'feedback-subscription', // /!\ is used for a prop
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: '/payment-required',
      component: FeedbackSubscriptionView,
      name: 'Payment Required',
      meta: { requiresAuth: true },
    },
    {
      path: '/token/:token',
      component: LoginAsView,
      name: 'Login as',
      meta: { guestsOnly: false },
      beforeEnter: clearLocalStorage,
    },
    {
      path: '/setup/:token',
      component: SetupToken,
      name: 'SetupToken ',
      meta: { guestsOnly: false },
      beforeEnter: clearLocalStorage,
    },
    {
      path: '/register',
      component: RegisterView,
      name: 'Register',
      meta: { guestsOnly: true },
    },
    {
      path: '/setup',
      component: SetupView,
      name: 'Setup',
      meta: { requiresAuth: true },
    },
    {
      path: '/confirm/details',
      component: ConfirmDetails,
      name: 'Confirm Details',
      meta: { requiresAuth: true },
    },
    {
      path: '/confirm/eula',
      component: ConfirmEula,
      name: 'Confirm Eula',
      meta: { requiresAuth: true },
    },
    {
      path: '/logout',
      component: LogoutView,
      name: 'Logout',
      meta: { description: 'Logout of app', requiresAuth: true },
    },
    {
      path: '/password/reset',
      component: ResetPasswordView,
      name: 'Reset Password',
      meta: { layout: 'guest-layout', requiresAuth: true },
      beforeEnter: isNotAuthenticated,
    },
    {
      path: '/forgotten',
      component: ForgottenView,
      name: 'Forgotten Password',
      meta: { description: 'Reset your password', guestsOnly: true },
    },
    {
      path: '/invite/:id',
      component: InviteView,
      name: 'Invite',
      meta: { description: 'You have been invited' },
    },
    {
      path: '/subscription/:action',
      component: SubscriptionProcessView,
      name: 'Subscription Process',
      meta: { description: 'Subscription' },
    },
    {
      path: '/',
      meta: { requiresAuth: true },
      component: DashView,
      children: [
        {
          path: 'dashboard',
          alias: '',
          component: DashboardView,
          name: 'Dashboard',
          meta: { description: 'Overview of system' },
        }, {
          path: 'subscription',
          component: MySubscriptionView,
          name: 'Subscription',
          meta: { description: 'Manage your schools subscription' },
        }, {
          path: 'account',
          component: AccountView,
          name: 'Account',
          meta: { description: 'Manage your account details' },
        }, {
          path: 'school',
          component: SchoolView,
          name: 'School',
          meta: { description: 'View your school\'s details' },
        }, {
          path: 'edit-school',
          component: EditSchoolView,
          name: 'Edit School',
          meta: {
            description: 'Edit your school\'s details',
            breadcrumb: [
              {
                name: 'school',
                path: '/school',
              },
              {
                name: 'edit school',
                path: false,
              },
            ],
          },
        }, {
          path: 'school/add-readers',
          component: AddPupilView,
          name: 'Add Pupils To School',
          meta: {
            breadcrumb: [
              {
                name: 'pupils',
                path: '/pupils',
              },
              {
                name: 'add pupil',
                path: false,
              },
            ],
          },
        }, {
          path: 'school/readers',
          redirect: { name: 'Pupils' },
        }, {
          path: 'pupils',
          component: SchoolPupilsView,
          name: 'Pupils',
          meta: { description: 'View the pupils enlisted in your school' },
        }, {
          path: 'pupils/:id',
          component: PupilView,
          name: 'Pupil',
          meta: {
            description: 'View this pupil\'s details',
            show_page_title: false,
            breadcrumb: [
              {
                name: 'pupils',
                path: '/pupils',
              },
              {
                name: 'pupil:name',
                path: false,
              },
            ],
          },
        }, {
          path: 'classes/:classId/pupils/:id',
          component: PupilView,
          name: 'Pupil ',
          meta: {
            description: 'View this pupil\'s details',
            breadcrumb: [
              {
                name: 'classes',
                path: '/classes',
              },
              {
                name: 'group:name',
                path: 'group:id',
              },
              {
                name: 'pupil:name',
                path: false,
              },
            ],
          },
        },
        {
          path: 'pupils/:id/books',
          component: PupilBooks,
          name: 'Add Books To Pupil',
          meta: {
            description: 'Manage which books the pupil can read',
            breadcrumb: [
              {
                name: 'pupils',
                path: '/pupils',
              },
              {
                name: 'pupil:name',
                path: 'pupil:id',
              },
              {
                name: 'books',
                path: false,
              },
            ],
          },
        },
        {
          path: 'classes/:classId/pupils/:id/books',
          component: PupilBooks,
          name: 'Add Books To Pupil',
          meta: {
            description: 'Manage which books the pupil can read',
            breadcrumb: [
              {
                name: 'classes',
                path: '/classes',
              },
              {
                name: 'group:name',
                path: 'group:id',
              },
              {
                name: 'pupil:name',
                path: 'pupil:id',
              },
              {
                name: 'books',
                path: false,
              },
            ],
          },
        },
        {
          path: 'pupils/:id/books/:bookId',
          component: PupilBookInfo,
          name: 'Pupil book info',
          meta: {
            description: 'See book info',
            breadcrumb: [
              {
                name: 'pupils',
                path: '/pupils',
              },
              {
                name: 'pupil:name',
                path: 'pupil:id',
              },
              {
                name: 'book:title',
                path: false,
              },
            ],
          },
        },
        {
          path: 'teachers',
          component: TeachersView,
          name: 'Teachers',
          meta: { description: 'Manage the teachers in your school' },
        }, {
          path: 'add-teacher',
          component: AddTeacherView,
          name: 'Add Teacher',
          meta: {
            authorization: 'add_teacher',
            breadcrumb: [
              {
                name: 'teachers',
                path: '/teachers',
              },
              {
                name: 'add teacher',
                path: false,
              },
            ],
          },
        }, {
          path: 'classes',
          component: ClassesView,
          name: 'Classes',
          meta: { description: 'Manage the classes in your school' },
        }, {
          path: 'classes/:id',
          component: ClassView,
          name: 'Class',
          meta: {
            description: 'View class details',
            breadcrumb: [
              {
                name: 'classes',
                path: '/classes',
              },
              {
                name: 'group:name',
                path: false,
              },
            ],
          },
        }, {
          path: 'classes/:classId/add-readers',
          component: AddPupilView,
          name: 'Add Pupils To Class',
          meta: {
            description: 'Add pupils to a class',
            breadcrumb: [
              {
                name: 'classes',
                path: '/classes',
              },
              {
                name: 'group:name',
                path: 'group:id',
              },
              {
                name: 'add pupils',
                path: false,
              },
            ],
          },
        }, {
          path: 'classes/:classId/books',
          component: BooksView,
          name: 'Add Books To Class',
          meta: {
            description: 'Manage which books the class can read',
            breadcrumb: [
              {
                name: 'classes',
                path: '/classes',
              },
              {
                name: 'group:name',
                path: 'group:id',
              },
              {
                name: 'books',
                path: false,
              },
            ],
          },
        },
        {
          path: 'overview',
          component: Overview,
          name: 'Help & Support',
          meta: { description: 'Overview', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'userguide',
          component: UserGuide,
          name: 'UserGuide',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'userguide/:tabId/:itemId',
          component: UserGuide,
          name: 'UserGuide Item',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'videoguides',
          component: VideoGuides,
          name: 'Video Guides',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'templates',
          component: Templates,
          name: 'Templates',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'faq',
          component: FAQs,
          name: 'FAQs',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'faq/:faqId',
          component: FAQs,
          name: 'FAQ Item',
          meta: { description: 'Find out how to use Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
        {
          path: 'contact',
          component: Contact,
          name: 'Contact',
          meta: { description: 'Find out how to contact Fonetti', showUserGuideDownloadButton: true, breadcrumb: false },
        },
      ],
    }, {
      // not found handler
      path: '*',
      component: NotFoundView,
      name: '',
    },
  ],
});
