export default {
  TOGGLE_LOADING(state) {
    state.callingAPI = !state.callingAPI;
  },
  IS_LOADING(state, value) {
    state.isLoading = value;
  },
  TOGGLE_SEARCHING(state) {
    state.searching = (state.searching === '') ? 'loading' : '';
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_EMAIL_ADDRESS(state, email) {
    state.emailAddress = email;
  },
  SET_ORIGIN(state, origin) {
    state.origin = origin;
  },
  ACCEPT_TERMS_AND_CONDITIONS(state, value) {
    state.termsAndConditionsAccepted = value;
  },
  HAS_SUBSCRIPTION(state, value) {
    state.hasSubscription = value;
  },
  HAS_PASSED_SUBSCRIPTION_SCREENING(state, value) {
    state.hasPassedSubscriptionScreening = value;
  },
  SET_CURRENT_GROUP(state, value) {
    state.currentGroup = value;
  },
  SET_CURRENT_SCHOOL(state, value) {
    state.currentSchool = value;
    state.school = value;
  },
  SET_CURRENT_CHARTS_GROUP(state, value) {
    state.currentChartsGroup = value;
  },
  SET_DEFAULT_DATE_RANGE(state, dates) {
    if (window.localStorage) {
      window.localStorage.setItem('default_date_range', JSON.stringify(dates));
    }
    state.defaultDateRange = dates;
  },
  SET_ROWS_PER_PAGE(state, n) {
    if (window.localStorage) {
      window.localStorage.setItem('number_of_rows', n);
    }
    state.rowsPerPage = n;
  },
  SET_CURRENT_PUPIL(state, pupil) {
    state.currentPupil = pupil;
  },
  SET_CURRENT_BOOK(state, book) {
    state.currentBook = book;
  },
  SET_SELECTED_SUBSCRIPTION_PACKAGE_ID(state, value) {
    state.selectedSubscriptionPackageId = value;
  },
  SET_INVOICE(state, value) {
    state.invoice = value;
  },
  HAS_OPEN_INVOICE(state, value) {
    state.hasOpenInvoice = value;
  },
  HAS_OUTSTANDING_INVOICE(state, value) {
    state.hasOutstandingInvoice = value;
  },
  SET_OPEN_INVOICE(state, value) {
    state.openInvoice = value;
  },
  IS_NEARING_END_OF_SUBSCRIPTION(state, value) {
    state.isNearingEndOfSubscription = value;
  },
  SET_SUBSCRIPTION_DAYS_LEFT(state, value) {
    state.subscriptionDaysLeft = value;
  },
  FROM_TRIAL(state, value) {
    state.fromTrial = value;
  },
};
