<template>
  <div id="app" :class="_.replace($route.name.toLowerCase(), ' ', '-') + '-page'">
     <modal title="An update is available" v-if="modal.isVisible('refreshRequired')" @close="handleCloseModal()">
      <div class="notification-body">
        <div class="u-margin-bottom-30">
          <p class="font12">An update to our school portal has just been released.</p>
          <p><span class="font10">Click the update now button to accept this update and reload the school portal. </span></p>
        </div>
        <div class="refresh-button text-center">
          <button class="btn btn-default" @click="reloadApp">Update now </button>
        </div>
      </div>
    </modal>
    <router-view></router-view>
  </div>
</template>

<script>
import refreshPageMixin from '@/components/mixins/RefreshPageMixin';
import Modal from '@/components/classes/Modal';
import ModalJS from '@/components/utils/Modal';
import config from '@/config';

export default {
  name: 'App',
  data() {
    return {
      section: 'Head',
      modal: new Modal({
        refreshRequired: false,
      }),
      shouldWatchForUpdates: config.shouldWatchForUpdates,
    };
  },
  mixins: [refreshPageMixin],
  components: { modal: ModalJS },
  created() {
    this.setBodyClasses();
    this.$http.interceptors.response.use((response) => response, (error) => {
      // Network errors don't have a response
      if (error.response === undefined) {
        return Promise.reject(error);
      }
      if (error.response.status === 403 || (error.response.status === 401 && error.response.data.error_type === 'generic' && error.response.data.error === 'Unauthorised')) {
        this.$router.push('logout');
        return Promise.reject(error);
      }
      return Promise.reject(error);
    });
    if (config.shouldWatchForUpdates) {
      this.initVersionCheck();
    }
  },
  methods: {
    handleCloseModal() {
      this.hashChanged = false;
      this.$nextTick(() => {
        this.modal.hide('refreshRequired');
      });
    },
    destroyUser() {
      this.$store.dispatch('destroyUser');
      this.$store.dispatch('destroyToken');
      this.$store.commit('ACCEPT_TERMS_AND_CONDITIONS', false);
    },
    logout() {
      this.$http.delete('auth')
        .then(() => {
          this.destroyUser();
          this.$router.push('/login');
        })
        .catch(() => {
          this.destroyUser();
          this.$router.push('/login');
        });
    },
    setBodyClasses() {
      // Detect if the current view has the sidebar e.c.t. There's certainly a better way (maybe router meta), but this was the quickest.
      const firstRouteMatchName = this.$route.matched.length > 0 ? this.$route.matched[0].components.default.name : null;
      const bodyBgClass = firstRouteMatchName === 'Dash' ? 'bg-swirls' : 'bg-school';
      document.body.classList.remove('bg-swirls', 'bg-school');
      document.body.classList.add(bodyBgClass);
    },
  },
  watch: {
    $route() {
      this.setBodyClasses();
    },
    hashChanged() {
      if (config.shouldWatchForUpdates && !this.modal.isVisible('refreshRequired')) {
        this.modal.show('refreshRequired');
      }
    },
  },
};
</script>
