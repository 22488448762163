<template>
  <div class="container">

        <div class="row" v-if="school">
          <div class="col-centered col-md-10">
            <div class="setup-form box-front">
              <img src="../assets/img/fonetti-logo.png" class="center-block logo">
              <action-messages></action-messages>
                <div class="box-header">
                  <h3 class="box-title">Confirm School Details</h3>
                  <p>Please confirm that these details are correct</p>
                </div>
                <div class="box-body">
                  <school-form v-model="school" @validated="confirmSchoolDetails" :is-loading="isLoading" origin="confirmDetails"></school-form>
                </div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import SchoolForm from '@/components/forms/SchoolForm';
import SchoolMixins from '@/components/mixins/SchoolMixins';

export default {
  name: 'setup',
  mixins: [CurrentUserMixin, SchoolMixins],
  components: { SchoolForm },
  data() {
    return {
      stage: 0,
      school: null,
      isLoading: false,
    };
  },
  beforeMount() {
    const userSchool = this.getUserSchool();
    this.getSchool(userSchool.id)
      .then((response) => {
        this.school = this.formatSchool(response);
      });
  },
  methods: {
    confirmSchoolDetails() {
      this.isLoading = true;
      this.$http.post(`enterprises/${this.school.id}`, { name: this.school.name, urn: this.school.urn, type: 'school' })
        .then(() => this.$http.post(`enterprises/${this.school.id}/contacts`, { contact: this.flattenContacts(this.school) }))
        .then(() => {
          this.getMe()
            .then(() => {
              this.$router.push('/confirm/eula');
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$apiResponse.renderErrorMessage(error);
        });
    },
  },
};
</script>
