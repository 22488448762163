import axios from 'axios';
import config from '../config';

axios.defaults.baseURL = config.serverURI;

export default {
  async login({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      axios.post('auth', credentials)
        .then((res) => {
          commit('SET_EMAIL_ADDRESS', credentials.email);
          const { user, meta } = res.data;
          /* Setting user in the state and caching record to the localStorage */
          if (user) {
            const { token } = meta;
            dispatch('setToken', token);

            resolve(user);
          }
          reject(new Error({ message: 'Login failed' }));
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({ commit, dispatch }) {
    commit('SET_EMAIL_ADDRESS', null);
    commit('SET_TOKEN', null);
    if (window.localStorage) {
      window.localStorage.clear('token');
    }
    dispatch('resetInvoices');
    dispatch('destroyUser');
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
    if (window.localStorage) {
      window.localStorage.setItem('token', token);
    }
    axios.defaults.headers.common['X-Auris-Token'] = token;
  },
  destroyToken({ commit }) {
    commit('SET_TOKEN', null);
    delete axios.defaults.headers.common['X-Auris-Token'];
    if (window.localStorage) {
      window.localStorage.removeItem('token');
    }
  },
  setUser({ commit }, user) {
    commit('SET_USER', user);
    commit('SET_CURRENT_SCHOOL', user.enterprise);
    if (window.localStorage) {
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  },
  setRowsPerPage({ commit }, n) {
    commit('SET_ROWS_PER_PAGE', n);
  },
  setDefaultDateRange({ commit }, dateRange) {
    commit('SET_DEFAULT_DATE_RANGE', dateRange);
  },
  setEmail({ commit }, email) {
    commit('SET_EMAIL_ADDRESS', email);
  },
  setOrigin({ commit }, origin) {
    commit('SET_ORIGIN', origin);
  },
  clearUser({ commit }) {
    commit('SET_USER', null);
    commit('SET_CURRENT_SCHOOL', null);
    if (window.localStorage) {
      window.localStorage.removeItem('user');
    }
  },
  destroyUser({ commit, dispatch }) {
    dispatch('clearUser');
    dispatch('destroyToken');
    commit('ACCEPT_TERMS_AND_CONDITIONS', false);
  },
  setCurrentGroup({ commit }, group) {
    commit('SET_CURRENT_GROUP', group);
  },
  clearCurrentGroup({ dispatch }) {
    dispatch('setCurrentGroup', null);
  },
  setCurrentPupil({ commit }, { id, name }) {
    commit('SET_CURRENT_PUPIL', { id, name });
  },
  clearCurrentPupil({ dispatch }) {
    dispatch('setCurrentPupil', { id: null, name: null });
  },
  setCurrentBook({ commit }, { id, title }) {
    commit('SET_CURRENT_BOOK', { id, title });
  },
  clearCurrentBook({ dispatch }) {
    dispatch('setCurrentBook', { id: null, title: null });
  },
  handleOpenInvoice({ commit }, { router, meta }) {
    commit('HAS_OPEN_INVOICE', meta.has_open_invoice);
    commit('HAS_OUTSTANDING_INVOICE', meta.has_outstanding_invoice);
    commit('SET_OPEN_INVOICE', meta.open_invoice);
    if (meta.has_outstanding_invoice) {
      router.replace({ path: '/payment-required', query: { show_sp_link: false } });
    }
  },
  handleNullOrExpiredSubscription({ _ }, { router, subscription }) { // eslint-disable-line
    if (!subscription) {
      return router.replace({ path: '/select-subscription' });
    }
    if (subscription.has_expired) {
      router.replace({ path: '/select-subscription', query: { show_sp_link: false } });
    }

    return true;
  },
  handleSubscriptionMeta({ commit }, { meta }) {
    commit('IS_NEARING_END_OF_SUBSCRIPTION', meta.is_nearing_end);
    commit('SET_SUBSCRIPTION_DAYS_LEFT', meta.num_of_days_before_end);
  },
  resetInvoices({ commit }) {
    commit('SET_INVOICE', null);
    commit('SET_OPEN_INVOICE', null);
    commit('HAS_OUTSTANDING_INVOICE', false);
    commit('HAS_OPEN_INVOICE', false);
  },
};
