<template>
  <div :class="['wrapper', classes]" v-show="ready">
    <header class="main-header">
      <a href="/" class="logo">
        <span class="logo-mini">
          <img src="@/assets/img/mascot.png" alt="Fonetti">
        </span>
        <span class="logo-lg">
          <img src="@/assets/img/fonetti-logo.png" alt="Fonetti">
        </span>
      </a>
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" role="navigation">
        <!-- Sidebar toggle button-->
        <a href="javascript:;" class="sidebar-toggle" data-toggle="offcanvas" role="button">
          <span class="sr-only">Toggle navigation</span>
        </a>
        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <!-- User Account Menu -->
            <li class="dropdown user-menu user">
                <!-- hidden-xs hides the username on small devices so only the image appears. -->
                <span class="hidden-xs">{{ activeUser.displayName }}</span>

            </li>
            <li class="dropdown user-menu account-type">
                <span>{{ userIsAdmin ? 'ADMIN' : 'TEACHER' }}</span>
            </li>

             <router-link to="/logout" class="logout">
                <i class="fa fa-circle-o text-red"></i>
                <span> Logout</span>
              </router-link>
          </ul>
        </div>
      </nav>
    </header>
    <!-- Left side column. contains the logo and sidebar -->
    <sidebar :display-name="activeUser.displayName" />
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <h1>
          {{ headerTitle }}
          <small>{{ headerDescription }}</small>
        </h1>
        <ol class="breadcrumb" v-if="$route.meta.breadcrumb">
          <li><a href="javascript:;" @click.prevent="$router.push('/')">Home</a></li>
          <li v-for="(crumb, index) in breadcrumbWithPath" :key="index + 'a'">
            <a href="javascript:;" @click.prevent="clickOnCrumb(crumb)" v-text="formatCrumbName(crumb)"></a>
          </li>
          <li v-for="(crumb, index) in breadcrumbWithoutPath" v-text="formatCrumbName(crumb)" class="active" :key="index + 'b'"></li>
        </ol>
        <ol class="breadcrumb" v-else-if="!$route.meta.showUserGuideDownloadButton">
          <li>
            <a href="javascript:;" @click.prevent="$router.push('/')">Home</a>
          </li>
          <li class="active">{{$route.name.toUpperCase()}}</li>
        </ol>
        <div class="download-button-container" v-if="$route.meta.showUserGuideDownloadButton">
          <download-button
            text="Download user guide"
            :lightGreen="true"
            href="/files/fonetti-school-portal-user-guide-4.1.pdf"
            suggestedDownloadFileName="Fonetti School Portal User Guide"
          />
        </div>

      </section>
      <section class="messages">
        <action-messages></action-messages>
      </section>
      <!-- <div class="container-fluid" v-if="fromTrial">
        <div class="global-info">You are here from trial</div>
      </div> -->
      <div class="container-fluid" v-if="$authorization.isAuthorized('manage_subscription')">
        <div class="global-alert" v-if="hasOpenInvoice">
          You have an unpaid invoice due by {{formatDate(openInvoice.due_date, 'Do MMMM YYYY')}}
        </div>
        <div class="global-alert" v-if="isNearingEndOfSubscription && !hasOpenInvoice && !isProcessingPayment">
          Your subscription ends {{formatSubscriptionDaysLeft()}}. <router-link to="/subscription">Extend your subscription</router-link>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <!-- /.content-wrapper -->
    <!-- Main Footer -->
    <footer class="main-footer">
      <a href="https://www.auris.tech" target="_blank">
          <img src="@/assets/img/auris.svg" width="572" height="163" />
      </a>
    </footer>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import config from '@/config';
import Sidebar from '@/views/Sidebar';
import 'hideseek';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';
import DownloadButton from '@/components/inputs/DownloadButton';
import Utils from '@/components/mixins/Utils';
import SchoolMixins from '@/components/mixins/SchoolMixins';

export default {
  name: 'Dash',
  components: {
    Sidebar,
    DownloadButton,
  },
  mixins: [SubscriptionMixin, Utils, SchoolMixins],
  data() {
    return {
      // section: 'Dash',
      year: new Date().getFullYear(),
      classes: {
        fixed_layout: config.fixedLayout,
        hide_logo: config.hideLogoOnMobile,
      },
      error: '',
      ready: this.$store.state.hasSubscription,
      header_title: null,
      header_description: null,
    };
  },
  created() {
    const adminLTEScript = document.createElement('script');
    adminLTEScript.setAttribute('src', '/js/plugins/AdminLTE/app.min.js');
    document.body.appendChild(adminLTEScript);

    if (!this.$store.state.hasSubscription) {
      this.handleSubscriptionProcess()
        .then((path) => {
          if (path === '/') {
            this.ready = true;
          } else {
            this.$router.push(path);
          }
        })
        .catch(() => {
          this.$router.push('/logout');
        });
    }

    this.$root.$on('SET_HEADER', (title, description) => {
      this.header_title = title;
      this.header_description = description;
    });
  },
  mounted() {
    this.getSchool(this.$store.state.user.enterprise.id, 'invoices,readers');
  },
  destroyed() {
    this.$store.commit('FROM_TRIAL', false);
  },
  computed: {
    ...mapState([
      'userInfo',
      'hasOpenInvoice',
      'openInvoice',
      'isNearingEndOfSubscription',
      'subscriptionDaysLeft',
      'fromTrial',
    ]),
    ...mapGetters([
      'getCurrentGroupName',
      'getCurrentGroupId',
      'currentSubscriptionIsTrial',
      'isProcessingPayment',
    ]),
    activeUser() {
      return {
        displayName: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
        email: this.$store.state.user.email,
      };
    },
    breadcrumbWithoutPath() {
      return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb.filter((crumb) => crumb.path === false) : [];
    },
    breadcrumbWithPath() {
      return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb.filter((crumb) => !!crumb.path) : [];
    },
    breadcrumb() {
      return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : [];
    },
    userIsAdmin() {
      return this.$store.state.user.enterprise_user_role === 'admin';
    },
    currentPupil() {
      return this.$store.state.currentPupil || null;
    },
    currentBook() {
      return this.$store.state.currentBook || null;
    },
    currentPupilIdFromUrl() {
      return this.$route.params.id;
    },
    currentGroupIdFromUrl() {
      return this.$route.params.classId;
    },
    headerTitle() {
      return this.header_title || this.$route.name.toUpperCase();
    },
    headerDescription() {
      return this.header_description || this.$route.meta.description;
    },
  },
  methods: {
    changeloading() {
      this.$store.commit('TOGGLE_SEARCHING');
    },
    formatCrumbName(crumb) {
      const re = new RegExp(':');
      if (re.test(crumb.name)) {
        const parts = crumb.name.split(':');
        let name = null;
        switch (parts[0]) {
          case 'group':
            name = this.getCurrentGroupName ? this.getCurrentGroupName : 'class';
            break;
          case 'pupil':
            name = this.currentPupil ? this.currentPupil.name : 'pupil';
            break;
          case 'book':
            name = this.currentBook ? this.currentBook.title : 'book';
            break;
          default:
            return;
        }
        if (this._.isEmpty(name)) {
          // eslint-disable-next-line consistent-return
          return 'loading...';
        }
        // eslint-disable-next-line consistent-return
        return name;
      }
      // eslint-disable-next-line consistent-return
      return crumb.name;
    },
    clickOnCrumb(crumb) {
      const re = new RegExp(':');
      if (re.test(crumb.path)) {
        const parts = crumb.path.split(':');
        switch (parts[0]) {
          case 'group':
            this.$router.push(`/classes/${this.currentGroupIdFromUrl}`);
            return;
          case 'pupil':

            if (this.currentGroupIdFromUrl) {
              this.$router.push(`/classes/${this.currentGroupIdFromUrl}/pupils/${this.currentPupilIdFromUrl}`);
            } else {
              this.$router.push(`/pupils/${this.currentPupilIdFromUrl}`);
            }
            return;
          default:
            return;
        }
      }
      // eslint-disable-next-line consistent-return
      return this.$router.push(crumb.path);
    },
    formatSubscriptionDaysLeft() {
      if (this.subscriptionDaysLeft === 0) {
        return 'today';
      }
      if (this.subscriptionDaysLeft === 1) {
        return 'tomorrow';
      }

      return `in ${this.subscriptionDaysLeft} days`;
    },
  },
  watch: {
    $route() {
      this.header_title = null;
      this.header_description = null;
    },
  },
};
</script>

<style lang="scss">
.wrapper.fixed_layout {
  .main-header {
    position: fixed;
    width: 100%;
  }

  .content-wrapper {
    padding-top: 50px;
  }

  .main-sidebar {
    position: fixed;
    height: 100vh;
  }
}

.wrapper.hide_logo {
  @media (max-width: 767px) {
    .main-header .logo {
      display: none;
    }
  }
}

.main-header .logo {
  text-align: center;
}

header .logo img {
  max-height: 30px;
}

.user-panel {
  height: 4em;
}

hr.visible-xs-block {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.17);
  height: 1px;
  border-color: transparent;
}

.nav.navbar-nav {
  margin-top: 1em;
  .logout {
    margin: 1em;
  }
}

.account-type {
  background-color: #bdbdbd;
  border-radius: 10px;
  padding: 1px 5px;
  color: #eee;
  margin-left: 10px;
  font-weight: 700;
  font-size: 0.8em;
  margin-top: 0.1em;
}
</style>
