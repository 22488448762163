<template>
  <ValidationObserver v-slot="{ passes }">
    <form ref="observer" class="ui form" @submit.prevent="passes(validateForm)">
      <fieldset>
        <!-- Name form input -->
        <ValidationProvider rules="required" name="Name" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="name" name="name" placeholder="School name" type="text" autocomplete="organization" v-model="mutableValue.school.name">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <!-- URN form input -->
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="urn" name="urn" placeholder="School Unique Reference Number (URN)" type="text" v-model="mutableValue.school.urn">
          </div>
        </div>
      </fieldset>

      <fieldset class="mt-2">
        <legend>School Address</legend>
        <ValidationProvider rules="required" name="Address Line 1" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="address_line_1" name="address_line_1" placeholder="Address Line 1" type="text" autocomplete="address-line1" v-model="mutableValue.contact.address_line_1">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_2" name="address_line_2" placeholder="Address Line 2" type="text" autocomplete="address-line2" v-model="mutableValue.contact.address_line_2">
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_3" name="address_line_3" placeholder="Address Line 3" type="text" autocomplete="address-line3" v-model="mutableValue.contact.address_line_3">
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input class="form-control" id="address_line_4" name="address_line_4" placeholder="Address Line 4" type="text" autocomplete="address-line4" v-model="mutableValue.contact.address_line_4">
          </div>
        </div>
        <div class="flex flex-gap-10">
          <ValidationProvider rules="required" name="Postcode" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
              <div class="input-group">
                <input class="form-control" id="postcode" name="postcode" placeholder="Post Code" type="text" autocomplete="postal-code" v-model="mutableValue.contact.postcode">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider ref="countryProvider" :rules="{ required: true }" name="Country" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
                <div class="input-group">
                <select data-testid="country-code" class="country-select form-control" name="country"
                        v-model="mutableValue.contact.country_code">
                  <option value="null" disabled selected hidden>Select a country</option>
                  <optgroup label="Most Common">
                    <option value="GBR">United Kingdom</option>
                    <option value="IND">India </option>
                    <option value="USA">United States of America </option>
                  </optgroup>
                  <optgroup label="All Countries">
                    <option v-for="(name, key) in countries.getNames('en')" :value="countries.alpha2ToAlpha3(key)"
                            :key="key">{{ Array.isArray(name) ? `${name[0]} (${name[1]})` : name }}
                    </option>
                  </optgroup>
                </select>
                <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <ValidationProvider ref="phoneProvider" :rules="{ required: true, regex: /^[+0-9]+$/ }" name="Phone" v-slot="{ errors }" slim>
          <div class="form-group" style="flex: 1 1 0px" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input data-testid="phone" class="form-control" name="phone" id="phone" placeholder="Phone" type="text" autocomplete="tel" v-model="mutableValue.contact.telephone">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </fieldset>
      <fieldset class="mt-2">
        <legend >Your login details</legend>
        <p class="mb-1">You will use these details to login to your school administrator account. You may create additional admin or teacher accounts once logged in.</p>
        <div class="flex flex-gap-10">
          <ValidationProvider :rules="{ required: true, min: 2 }" name="First Name" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
              <div class="input-group">
                <input class="form-control" id="first_name" name="first_name" placeholder="First Name" type="text" autocomplete="given-name" v-model="mutableValue.user.first_name">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider :rules="{ required: true, min: 2 }" name="Last Name" v-slot="{ errors }" slim>
            <div class="form-group flex-same-width" :class="{ 'has-error': errors[0] }">
              <div class="input-group">
                <input class="form-control" id="last_name" name="last_name" placeholder="Last Name" type="text" autocomplete="family-name" v-model="mutableValue.user.last_name">
              </div>
              <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
        <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }" slim>
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="email" name="email" placeholder="Email address" type="email" autocomplete="email" v-model="mutableValue.user.email">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider :rules="{ required: true, min: 7 }" name="Password" v-slot="{ errors }">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="input-group">
              <input class="form-control" id="password" name="password" placeholder="Password" type="password" autocomplete="new-password" v-model="mutableValue.user.password">
            </div>
            <span v-show="errors" class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="EULA" v-slot="{ errors }" slim>
          <div class="form-group checkbox align-checkbox mt-2" :class="{ 'has-error': errors[0] }">
            <input id="eula" name="eula" type="checkbox" v-model="mutableValue.user.eula">
            <label for="eula" :class="{ 'has-error': errors[0] }"> I agree to the <a href="https://www.fonetti.com/wp-content/uploads/2021/02/School-EULA-v1.pdf" target="_blank" rel="noopener noreferrer" alt="opens in new window">End User License Agreement</a></label>
          </div>
        </ValidationProvider>
        <ValidationProvider :rules="{ required: { allowFalse: false } }" name="T&C" v-slot="{ errors }" slim>
          <div class="form-group checkbox align-checkbox" :class="{ 'has-error': errors[0] }">
            <input id="tc" name="tc" type="checkbox" v-model="mutableValue.user.tc">
            <label for="tc" :class="{ 'has-error': errors[0] }">I agree to the <a href="https://www.fonetti.com/terms-of-use/" target="_blank" rel="noopener noreferrer" alt="opens in new window">Terms &amp; Conditions</a> and have read the <a href="https://www.fonetti.com/privacy-policy/" target="_blank" alt="opens in new window">Privacy Policy</a></label>
          </div>
        </ValidationProvider>
      </fieldset>
      <loading-button class="btn-block" :is-loading="isLoading">Continue</loading-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import FormMixin from '@/components/mixins/FormMixin';
import LoadingButton from '@/components/utils/LoadingButton';

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'register-school-form',
  components: {
    LoadingButton,
  },
  mixins: [FormMixin],
  props: ['value'],
  data() {
    return {
      countries,
    };
  },
  computed: {
    ...mapState([
      'isLoading',
    ]),
    mutableValue() {
      return Object.assign(this.value);
    },
  },
};
</script>
