<template>
  <div class="container">
    <div class="select-subscription">
      <action-messages></action-messages>
      <!-- school admin -->
      <subscription-selector v-if="hasAccess && school" :school-id="school.id" :is-new="true" @select="selectPackage" />

      <!-- teacher -->
      <div class="row" v-if="hasAccess === false">
        <div class="col-centered col-md-8">
          <div class="select-subscription box-front">
            <img src="../../assets/img/fonetti-logo.png" class="center-block logo">
            <p>Your school subscription has expired, please speak to your school administrator who can re-subscribe to Fonetti.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentUserMixins from '@/components/mixins/CurrentUserMixins';
import SubscriptionSelector from '@/components/partials/SubscriptionSelector';
import SubscriptionMixin from '@/components/mixins/SubscriptionMixin';

export default {
  components: {
    SubscriptionSelector,
  },
  mixins: [CurrentUserMixins, SubscriptionMixin],
  data() {
    return {
      school: null,
      hasAccess: null,
    };
  },
  mounted() {
    if (this.$authorization.isAuthorized('manage_subscription')) {
      this.hasAccess = true;
      this.school = this.getUserSchool();
    } else {
      this.hasAccess = false;
    }
  },
};
</script>

<style lang="scss" scoped>
#app .box-front .logo {
  margin-bottom: 10px;
}

h2 {
  text-align: center;
}

.select-subscription {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
</style>
