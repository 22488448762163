import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import _ from 'lodash';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import {
  required,
  email,
  min,
  regex,
  ext,
  confirmed,
} from 'vee-validate/dist/rules';
import axios from 'axios';
// Import Plugins
import Authorization from '@/plugins/Authorization';
import APIResponse from '@/plugins/APIResponse';
import {
  domain,
  count,
  prettyDate,
  pluralize,
} from '@/filters';
import FloatingVue from 'floating-vue';
import VueFathom from './plugins/vue-fathom';
import config from './config';
import App from './App';
import router from './router';
import store from './store';
import './assets/scss/main.scss';
// Import Helpers for filters

// Begin bugsnag
Bugsnag.start({
  apiKey: '92fa8f00ffefb67d50e58c839414d186',
  plugins: [new BugsnagPluginVue()],
  releaseStage: config.releaseStage,
  enabledReleaseStages: ['production', 'staging', 'development'],
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

// Global properties

// Axios global setup
Object.defineProperty(Vue.prototype, '$http', { value: axios });
const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['X-Auris-Token'] = token;
}
Vue.prototype.$http.defaults.baseURL = config.serverURI;

// End axios setup

Object.defineProperty(Vue.prototype, '_', { value: _ });

Vue.config.productionTip = false;

// Import Install and register helper items
Vue.filter('count', count);
Vue.filter('domain', domain);
Vue.filter('prettyDate', prettyDate);
Vue.filter('pluralize', pluralize);

Vue.use(Authorization, { store });
Vue.use(APIResponse, { router });
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
extend('confirmed', {
  ...confirmed,
  message: en.messages.confirmed,
});
extend('required', {
  ...required,
  message: en.messages.required,
});
extend('email', {
  ...email,
  message: en.messages.email,
});
extend('min', {
  ...min,
  message: en.messages.min,
});
extend('regex', {
  ...regex,
  message: en.messages.regex,
});
extend('ext', {
  ...ext,
  message: en.messages.ext,
});
extend('postcode', {
  validate(value) {
    return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/.test(value);
  },
  message: 'The Postcode is invalid',
});

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  if (to.path.includes('/setup/') || to.path.includes('/token/') || to.path.includes('/password/reset')) {
    next();
  } else if (to.matched.some((record) => record.meta.requiresAuth)
    && (!store.state.token || store.state.token === 'null' || store.state.token === null)) {
    if (to.path === '/login') {
      next();
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
  // redirect to relevant page if password reset required or terms not accepted
  } else if (store.state.user && store.state.user.require_password_reset) {
    if (to.path === '/setup') {
      next();
    } else {
    // 'go to password reset'
      next({
        path: '/setup',
      });
    }
  } else if (store.state.user && store.state.user.enterprise && 'terms_accepted_at' in store.state.user.enterprise && !store.state.user.enterprise.terms_accepted_at) {
    // 'go to confirm details -> confirm eula'
    if (to.path === '/confirm/details' || to.path === '/confirm/eula') {
      next();
    } else {
      next({
        path: '/confirm/details',
      });
    }
  } else if (to.matched.some((record) => record.meta.guestsOnly)
    && (store.state.token !== 'null' && store.state.token !== null)) {
    next({
      path: '/',
    });
  } else if (to.matched.some((record) => record.meta.authorization)
    && (!router.app.$authorization.isAuthorized(to.meta.authorization))) {
    next({
      path: '/',
    });
  } else {
    next();
  }
});

// Check local storage to handle refreshes
if (window.localStorage) {
  // window.localStorage.getItem returns the string 'null' if empty this makes if statments simpler
  const localUserString = window.localStorage.getItem('user');
  const localUser = localUserString === 'null' ? null : JSON.parse(localUserString);
  const localTokenString = window.localStorage.getItem('token');
  const localToken = localTokenString === 'null' ? null : localTokenString;
  if (localToken) {
    store.dispatch('setToken', localToken);
    // update user object before storing it in vuex and local storage
    axios.get('users/me')
      .then((r) => {
        const { data } = r;
        if (data.user) {
          store.dispatch('setUser', data.user);
        }
      });
  }
  if (localUser) {
    store.dispatch('setUser', localUser);
  }
}

// Global helper method for overriding the pages default title and description
// Ideally this would be exposed to page view type components only, but I don't want to
// have to import a mixin manually to every view component.
Vue.mixin({
  methods: {
    setPageTitle(title, description = null) {
      this.$root.$emit('SET_HEADER', title, description);
    },
  },
});

// Add FloatingVue (tooltips)
Vue.use(FloatingVue);

// Fathom
if (config.fathomAnalyticsSiteId !== null) {
  Vue.use(VueFathom, {
    router,
    store,
    siteID: config.fathomAnalyticsSiteId,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#root');
