export default {
  getCurrentGroupName: (state) => (state.currentGroup ? state.currentGroup.name : null),
  getCurrentGroupId: (state) => (state.currentGroup ? state.currentGroup.id : null),
  getCurrentSchoolId: (state) => (state.currentSchool ? state.currentSchool.id : state.user && state.user.enterprise ? state.user.enterprise.id : null),
  groups: (state) => (state.currentSchool ? state.currentSchool.groups : []),
  currentSubscriptionIsTrial: (state) => (state.currentSchool ? (state.currentSchool.subscription ? state.currentSchool.subscription.is_trial : false) : false),
  currentSubscriptionInvoiceId: (state) => (state.currentSchool && state.currentSchool.subscription ? state.currentSchool.subscription.payment_provider_subscription_id : null),
  invoices: (state) => (state.currentSchool && state.currentSchool.invoices ? state.currentSchool.invoices.data : []),
  invoicesMeta: (state) => (state.currentSchool && state.currentSchool.invoices ? state.currentSchool.invoices.meta : null),
  getCurrentSchoolName: (state) => (state.currentSchool ? state.currentSchool.name : state.user && state.user.enterprise ? state.user.enterprise.name : ''),
  getDefaultDateRange: (state) => {
    if (state.defaultDateRange === null) {
      return JSON.parse(window.localStorage.getItem('default_date_range'));
    }

    return state.defaultDateRange;
  },
  isLoggedIn: (state) => !!state.token,
  isProcessingPayment: (state) => (!!(state.currentSchool && state.currentSchool.subscription && state.currentSchool.subscription.renewal_status === 'awaiting_payment' && (!state.hasOpenInvoice && !state.hasOutstandingInvoice))),
};
