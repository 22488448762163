<template>
  <!-- Main content -->
  <section class="content" v-if="pageReady">
    <div class="charts" v-if="hasChartData">
      <div class="row panel panel-default">
        <div class="panel-heading">
          <h2>{{capitalisedClassName}}</h2>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <div class="input-group">
                  <label for="group">Choose a class</label>
                  <select class="form-control" id="group" name="group" v-model="selectedGroupId" @change="handleChangeGroupId">
                    <option :value="group.id" v-for="group in groups" :key="group.id">{{ group.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12">
              <div class="form-group">
                <div class="input-group date-picker-group">
                  <label for="datepicker" class="date-picker-label">Choose a date range</label>
                  <date-picker-with-dropdown
                  reference="time_period"
                  @date-range-change="dateRangeUpdated"
                  v-model="dateRange"
                  :initialDateRange="dateRange"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="charts">
        <div class="charts-container">
          <chart :chart="charts.readers_reading_time_by_reading_level" :isLoading="isLoading"></chart>
        </div>
        <div class="charts-container">
          <chart :chart="charts.readers_accuracy_by_reading_level" :isLoading="isLoading"></chart>
        </div>
        <div class="charts-container">
          <chart :chart="charts.readers_read_books_by_reading_level" :isLoading="isLoading"></chart>
        </div>
        <div class="charts-container">
          <chart :chart="charts.group_reading_time_by_reading_level_over_time" :isLoading="isLoading"></chart>
        </div>
        <div class="charts-container">
          <chart :chart="charts.group_accuracy_by_reading_level_over_time" :isLoading="isLoading"></chart>
        </div>
      </div>
      <div class="row" v-if="selectedGroup && selectedGroup.readers_count === 0">
        <div class="col-md-12">
          <p>No pupils in this class - <button class="btn btn-primary" @click="$router.push('classes/' + selectedGroupId($event))">add a pupil here</button></p>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-md-12">
        <h2 class="text-center">Welcome to the Fonetti School Portal</h2>
        <p class="text-center">As you start using Fonetti, the dashboard will provide simple and useful insights into how your classes and pupils are performing.</p>
        <div class="box box-info mt-2">
          <div class="box-header with-border">
            <h3 class="box-title">Complete setting up your school</h3>
          </div>
          <div class="box-body">
            <ul class="setup-goals">
              <li :class="hasSetupTeachers ? 'complete' : 'incomplete'" v-if="school.user_manages"><router-link to="/teachers">Invite other teachers</router-link></li>
              <li :class="hasSetupClasses ? 'complete' : 'incomplete'"><router-link to="/classes">Create your classes</router-link></li>
              <li :class="hasSetupPupils ? 'complete' : 'incomplete'"><router-link to="/school/add-readers">Add your pupils</router-link></li>
              <li :class="hasSetupAssignedBooks ? 'complete' : 'incomplete'"><span>Assign your pupils some books to read</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- /.content -->
</template>
<script>
import _ from 'lodash';
import ClassesMixin from '@/components/mixins/ClassesMixin';
import Utils from '@/components/mixins/Utils';
import CurrentUserMixin from '@/components/mixins/CurrentUserMixins';
import ChartMixin from '@/components/mixins/ChartMixin';
import DatePickerWithDropdown from '@/components/inputs/DatePickerWithDropdown';
import { Chart, ChartCanvas, ChartConfig } from '@/plugins/Chart';
import moment from 'moment';
import SchoolMixins from '@/components/mixins/SchoolMixins';
import { mapGetters, mapState } from 'vuex';

const chartColors = {
  'Reading level 1': '#bd2f9e', 'Reading level 2': '#c33a2c', 'Reading level 3': '#f9e106', 'Reading level 4': '#5ac9ee', 'Reading level 5': '#00954f', 'Reading level 6': '#f88124', 'Reading level 12': '#bfd72f', Total: '#757575',
};

export default {
  name: 'Dashboard',
  mixins: [SchoolMixins, ClassesMixin, CurrentUserMixin, ChartMixin, Utils],
  components: {
    chart: ChartCanvas,
    DatePickerWithDropdown,
  },
  data() {
    return {
      charts: {
        group_accuracy_by_reading_level_over_time: new Chart('group_accuracy_by_reading_level_over_time',
          new ChartConfig()
            .setMountedCallBack(this.stopLoading)
            .setUpdatedCallBack(this.stopLoading)
            .withTitle('Class accuracy')
            .withAnnotationOnYAxis('Average accuracy')
            .withYAxisFormatter(this.percentageFormatter)
            .isTimeSeries()
            .setDataLabels({ enabled: false })
            .disableNegativeYAxis()
            .setColors(chartColors)),
        group_reading_time_by_reading_level_over_time: new Chart('group_reading_time_by_reading_level_over_time',
          new ChartConfig()
            .setMountedCallBack(this.stopLoading)
            .setUpdatedCallBack(this.stopLoading)
            .isTimeSeries()
            .withTitle('Class reading time')
            .withAnnotationOnYAxis('Average reading time')
            .withYAxisFormatter(this.yAxisReadingTime)
            .withToolTipFormatter(this.tooltipReadingTime)
            .setDataLabels({ enabled: false })
            .disableNegativeYAxis()
            .setColors(chartColors)),
        readers_accuracy_by_reading_level: new Chart('readers_accuracy_by_reading_level',
          new ChartConfig()
            .setMountedCallBack(this.stopLoading)
            .setUpdatedCallBack(this.stopLoading)
            .withTitle('Pupils\' accuracy')
            .withAnnotationOnYAxis('Average accuracy')
            .withYAxisFormatter(this.percentageFormatter)
            .setYAxisMax(100)
            .setDataLabels({ enabled: false })
            .isType('bar')
            .setColors(chartColors)),
        readers_read_books_by_reading_level: new Chart('readers_read_books_by_reading_level',
          new ChartConfig()
            .setMountedCallBack(this.stopLoading)
            .setUpdatedCallBack(this.stopLoading)
            .withTitle('Pupils\' Read Books')
            .withYAxisFormatter(this.totalBooksFormatter)
            .setDataLabels({ enabled: true, total: { enabled: true } })
            .setYAxisTickAmount(1)
            .isType('bar')
            .isStacked()
            .setColors(chartColors)),
        readers_reading_time_by_reading_level: new Chart('readers_reading_time_by_reading_level',
          new ChartConfig()
            .setMountedCallBack(this.stopLoading)
            .setUpdatedCallBack(this.stopLoading)
            .withTitle('Pupils\' reading time')
            .withAnnotationOnYAxis('Average reading time')
            .withYAxisFormatter(this.yAxisReadingTime)
            .withToolTipFormatter(this.tooltipReadingTime)
            .setDataLabels({ enabled: false })
            .isType('bar')
            .setColors(chartColors)),
      },
      // groups: [],
      selectedGroup: null,
      selectedGroupId: null,
      selectedGroupName: null,
      pageReady: false,
      assignedBooks: false,
      isLoading: false,
      dateRange: {
        start: this.$store.state.defaultDateRange ? moment(this.$store.state.defaultDateRange.startDate).toDate() : moment().startOf('month').startOf('day').toDate(),
        end: this.$store.state.defaultDateRange ? moment(this.$store.state.defaultDateRange.endDate).toDate() : moment().endOf('day').toDate(),
      },
      setup: null,
    };
  },
  created() {
    Chart.setCallback(this.getChart);
  },
  mounted() {
    this.$nextTick(() => {
      this.getSchool(this.getCurrentSchoolId, 'setup')
        .then((school) => {
          this.setup = school.setup;
          if (this.hasChartData) {
            this.getGroupsData();
          }
          this.pageReady = true;
        });
    });
  },
  methods: {
    getGroupsData() {
      if (this.school.id) {
        this.pageReady = true;
        if (this._.isEmpty(this.groups)) {
          return;
        }
        this.selectedGroup = this.$store.state.currentChartsGroup ? this.$store.state.currentChartsGroup : this.groups[0];
        this.selectedGroupId = this.selectedGroup.id;
        this.selectedGroupName = this.selectedGroup.name;
      }
    },
    fetchCharts() {
      _.forEach(this.charts, (chart) => {
        chart.setGroupId(this.selectedGroupId);
        chart.config.setCSVFileName(this.selectedGroupName);
        chart.config.setChartId(this.selectedGroupName);
        if (this.startDate) {
          chart.setStartDate(this.startDate);
        } else {
          chart.setStartDate(moment(this.dateRange.start));
        }
        if (this.endDate) {
          chart.setEndDate(this.endDate);
        } else {
          chart.setEndDate(moment(this.dateRange.end));
        }
        chart.setOutput(this.output);
      });
      this.fetchDataForCharts(this.charts);
    },
    dateRangeUpdated(dates) {
      // format the dates object so it works with the old system
      const { start, end } = dates;
      const momentDates = { startDate: moment(start), endDate: moment(end) };
      this.$store.dispatch('setDefaultDateRange', momentDates);
      this.startDate = dates.start;
      this.endDate = dates.end;
      this.fetchCharts();
    },
    handleChangeGroupId(e) {
      // eslint-disable-next-line prefer-destructuring
      const groups = this.groups;
      this._.forEach(groups, (group) => {
        if (group.id === parseInt(e.target.value, 10)) {
          this.selectedGroup = group;
          this.selectedGroupName = this.selectedGroup.name;
          this.$store.commit('SET_CURRENT_CHARTS_GROUP', group);
        }
      });
      this.fetchCharts();
    },
    capitalize_Words(str) {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    },
  },
  computed: {
    ...mapState([
      'school',
      'user',
    ]),
    ...mapGetters([
      'groups',
      'getCurrentSchoolId',
    ]),
    readingSessionsTotal() {
      return this.charts.readingSessions.total() === null ? 0 : this.charts.readingSessions.total();
    },
    readingTimeTotal() {
      return this.charts.readingTime.total() === null ? 0 : this.tooltipReadingTime(this.charts.readingTime.total());
    },
    capitalisedClassName() {
      return this.capitalize_Words(this.selectedGroupName);
    },
    currentPupil() {
      return this.$store.state.currentPupil || null;
    },
    hasSetupTeachers() {
      return this.setup && this.setup.has_setup_teachers;
    },
    hasSetupClasses() {
      return this.setup && this.setup.has_setup_classes;
    },
    hasSetupPupils() {
      return this.setup && this.setup.has_setup_pupils;
    },
    hasSetupAssignedBooks() {
      return this.setup && this.setup.has_setup_assigned_books;
    },
    hasChartData() {
      return this.setup && this.setup.has_chart_data;
    },
  },
  watch: {
    selectedGroup() {
      if (this.selectedGroup) {
        this.fetchCharts();
      }
    },
    assignedBooks() {
      this.fetchCharts();
    },
  },
};
</script>

<style lang="scss" scoped>
  .date-picker-label {
    margin-left: 0;
  }
  .date-picker-group {
    width: auto;
  }

  ul.setup-goals {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
      &:before {
        display: inline-block;
        font-family: FontAwesome;
        margin-right: 0.3em;
      }

      &.complete {
        a, span { color: #079c07; }
        &:before {
          color: #079c07;
          content: "\f058";
        }
      }

      &.incomplete {
        a, span { color: #013057; }
        &:before  {
          color: #aaa;
          content: "\f0a9";
        }
      }
    }

  }
</style>
