<template>
  <iframe class="contact" width="100%" :height="iHeight" :src="iframeLink"></iframe>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'contact-iframe',
  props: {
    height: {
      required: false,
      default: 'auto',
    },
  },
  data() {
    return {
      iHeight: 0,
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
    ...mapGetters([
      'getCurrentSchoolName',
      'getCurrentSchoolId',
    ]),
    iframeLink() {
      const url = new URL('https://forms.zohopublic.eu/auristech/form/SchoolPortalEnquiryForm/formperma/_IqdrIfQ7pHzew2ro2IHlLSSPNJZbeqicWJgq9fj3ko');
      // const url = new URL('https://forms.zohopublic.eu/auristech/form/SchoolEnquiryForm/formperma/GhQ3a-BjhNHWT-owljjgmFCWNX7_wgfz5z_mZQIulaU');
      url.searchParams.set('zf_rszfm', 1); // This enables the JS event data
      url.searchParams.set('Fname', this.user.first_name);
      url.searchParams.set('Lname', this.user.last_name);
      url.searchParams.set('Email', this.user.email);
      url.searchParams.set('SchoolName', this.getCurrentSchoolName);
      url.searchParams.set('SchoolId', this.getCurrentSchoolId);
      return url.toString();
    },
  },
  created() {
    this.iHeight = this.height !== 'auto' && parseInt(this.height, 10) > 0 ? this.height : this.iHeight;
  },
  mounted() {
    if (this.height === 'auto') {
      window.addEventListener('message', this.windowMessage);
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.windowMessage);
  },
  methods: {
    windowMessage(evt) {
      const { data } = evt;
      if (data && data.constructor === String) {
        const [, height] = data.split('|');
        if (height && parseFloat(height) > 0) {
          this.iHeight = parseFloat(height) + 15; // No idea what +15 is for, Zoho do it on their embed code, presume it's a hack to avoid minute scrolling
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  border: none;
  transition: height 0.25s ease;
}
</style>
