<template>
  <div class="small-buttons-locator">
    <a @click="$emit('show-book-details', book)" class="btn btn-primary"><i class="icon icon-info"></i> Book info</a>
    <a @click="$emit('open-preview', book)" class="btn btn-primary btn-primary-yellow"><i class="icon icon-search-light"></i> Preview book</a>
  </div>
</template>
<script>
export default {
  props: {
    book: {
      required: false,
    },
  },
  name: 'book-buttons',
};
</script>

<style scoped>
  .shuffle .btn-primary {
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
  }
  .shuffle .btn-primary .icon::before {
    vertical-align: -1px;
  }
  .list-container:not(:first-child) {
    margin-top:2em;
  }
  .list-title {
    font-weight:300;
    margin: 0 4px 0.2em 4px;
  }
</style>
