<template>
  <div>
    <modal title="Book Info" v-if="modal.isVisible('viewBookInfo')" @close="modal.hide('viewBookInfo')" :show-footer="false">
      <book-details :book="chosenBook"></book-details>
    </modal>
    <modal title="Unassign Book" v-if="modal.isVisible('unassign')" @close="modal.hide('unassign')">
      <unassign-modal-content
        @yes="handleRemoveBook()"
        @no="modal.hide('unassign')"
      />
    </modal>
    <div class="row">
      <div class="col-md-12">
        <div class="row" v-if="currentEntityType === EntityType.PUPIL">
          <div class="col-md-6">
            <h2 class="panel-heading">{{pupilName}}</h2>
          </div>
        </div>
        <div class="shuffle">
            <div class="col-md-9">
              <div class="search form-inline">
                <label>
                  <i class="icon icon-search"></i> <input type="search" class="form-control input-sm" placeholder="Search for a book" v-model="searchTerm">
                </label>
              </div>
            </div>
            <div class="col-md-3">
              <div class="filters">
                <label for="reading_level_start">Reading Levels from</label>
                <select name="reading_level_start" id="reading_level_start" v-model="readingLevelStart">
                  <option v-for="n in maxReadingLevel" :value="n" :key="n" :disabled="n > readingLevelEnd">{{ n }}</option>
                </select>
                <label for="reading_level_end">To</label>
                <select name="reading_level_end" id="reading_level_end" v-model="readingLevelEnd">
                  <option v-for="n in maxReadingLevel" :value="n" :key="n" :disabled="n < readingLevelStart">{{ n }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="tags">
                <div class="btn-group" role="group">
                  <button class="btn btn-primary" :class="{'active': tag === 'all'}" @click="filter('all')">All books
                  </button>
                  <button class="btn btn-primary" :class="{'active': tag === 'assigned'}" @click="filter('assigned')">Only
                    Assigned
                  </button>
                  <button class="btn btn-primary" :class="{'active': tag === 'unassigned'}" @click="filter('unassigned')">Only
                    Unassigned
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="list-actions-container">
                <div class="list-actions">
                  <button @click="bulkAssign" class="btn btn-assign-remove btn-success" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign all on view</button>
                  <button @click="startBulkUnassign()" class="btn btn-assign-remove btn-danger" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove all on view</button>
                </div>
              </div>
            </div>
            <div class="col-md-12">
            <div v-for="(group, index) in groupedBooks" :key="index" class="list-container">
              <h2 class="list-title">{{group.groupTitle}}</h2>
              <transition-group name="list" class="list" tag="section">
              <div v-for="(book, index) in group.books" :key="book.id" class="list-item" :class="book.is_assigned ? 'assigned' : 'unassigned'">
                <div class="list-item-inner">
                  <img :src="fixCoverImageUrl(book.cover_image)" alt="" class="list-img">
                  <div class="title">{{ book.title }}</div>
                  <div v-if="currentEntityType === 0">
                    <div class="overlay" v-if="book.is_assigned_via_group">
                      <div class="inner">
                        <button @click="startUnassignBook(book.id, index)" class="btn btn-assign-remove" :class="removingBook ? 'btn-link' : 'btn-danger'" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove from class</button>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview(book)"/>
                      </div>
                    </div>
                    <div class="overlay" v-else>
                      <div class="inner">
                        <button @click="assignBook({entityType: currentEntityType, id: book.id, index: index, pupilId: pupilId, groupId: groupId})" class="btn btn-assign-remove" :class="assigningBook ? 'btn-link' : 'btn-success'" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign to class</button>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview(book)"/>
                      </div>
                    </div>
                  </div>
                  <div v-if="currentEntityType === 1">
                    <div class="overlay" v-if="book.is_assigned_via_group">
                      <div class="inner">
                        <p>This book is assigned through class</p>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview(book)"/>
                      </div>
                    </div>
                    <div class="overlay" v-else-if="book.is_assigned_to_reader">
                      <div class="inner">
                        <button @click="startUnassignBook(book.id, index)"  class="btn btn-assign-remove" :class="removingBook ? 'btn-link' : 'btn-danger'" :disabled="removingBook"><i class="fa" :class="removingBook ? 'fa-spinner fa-pulse' : 'fa-minus'"></i> Remove from {{ entityTypeString }}</button>
                        <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview(book)"/>
                      </div>
                    </div>
                    <div class="overlay" v-else>
                      <div class="inner">
                        <button @click="assignBook({entityType: currentEntityType, id: book.id, index: index, pupilId: pupilId, groupId: groupId})" class="btn btn-assign-remove" :class="assigningBook ? 'btn-link' : 'btn-success'" :disabled="assigningBook"><i class="fa" :class="assigningBook ? 'fa-spinner fa-pulse' : 'fa-plus'"></i> Assign to {{ entityTypeString }}</button>
                       <book-buttons @show-book-details="showBookDetails(book)" @open-preview="openPreview(book)"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BooksMixin from '@/components/mixins/BooksMixin';
import Modal from '@/components/classes/Modal';
import ModalJS from '@/components/utils/Modal';
import BookDetails from '@/components/partials/BookDetails';
import UnassignModalContent from '@/components/partials/UnassignModalContent';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import BookButtons from '@/components/partials/BookButtons';

const BOOK_GROUP_TITLES = {
  edubrisk: 'EduBrisk Titles',
  'fonetti-premium': 'Fonetti Titles',
  'poems-and-pictures-ltd': 'Poems and stories by Christina Gabbitas',
  'martin-roberts-the-villes': 'The Villes by Martin Roberts',
  'various-single-purchases': 'Various Authors',
  cornerstones: 'Cornerstones',
  default: 'Additional Titles',
};

export default {
  name: 'library-info',
  mixins: [BooksMixin],
  components: {
    modal: ModalJS,
    UnassignModalContent,
    BookDetails,
    BookButtons,
  },
  props: {
    books: {
      type: Array,
      required: true,
    },
    currentEntityType: {
      type: Number,
      required: true,
    },
    pupilName: {
      required: true,
    },
    groupId: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      tag: 'all',
      removingBook: false,
      removingBooks: false,
      assigningBook: false,
      searchTerm: '',
      chosenBook: null,
      shouldShowRemoveBookWarning: true,
      modal: new Modal({
        viewBookInfo: false,
        unassign: false,
      }),
      bookToRemoveId: null,
      bookToRemoveIndex: null,
    };
  },
  methods: {
    openPreview(bookInfo) {
      const url = `https://d3vlekh17wu64n.cloudfront.net/book_pdfs/${bookInfo.id}.pdf`;
      window.open(url);
    },
    updateBookToRemove(bookInfo) {
      const { id, index } = bookInfo;
      this.bookToRemoveId = id;
      this.bookToRemoveIndex = index;
    },
    handleRemoveBook() {
      this.startRemoveBookWarningTimeOut();
      if (this.removingBooks) {
        this.bulkUnassign();
      } else {
        this.removeBook({
          entityType: this.currentEntityType,
          id: this.bookToRemoveId,
          index: this.bookToRemoveIndex,
          pupilId: this.pupilId,
          schoolId: this.schoolId,
          groupId: this.groupId,
        });
      }
      this.modal.hide('unassign');
    },
    handleCloseUnassignModal() {
      this.updateBookToRemove({ id: null, index: null });
      this.removingBooks = false;
      this.modal.hide('unassign');
    },
    startRemoveBookWarningTimeOut() {
      this.shouldShowRemoveBookWarning = false;
      setTimeout(() => {
        this.shouldShowRemoveBookWarning = true;
      }, 300000);
    },
    resetBookToRemove() {
      this.bookToRemoveId = null;
      this.bookToRemoveIndexindex = null;
    },
    startUnassignBook(id, index) {
      this.bookToRemoveId = id;
      this.bookToRemoveIndex = index;
      if (this.shouldShowRemoveBookWarning) {
        this.modal.show('unassign');
      } else {
        this.removeBook({
          entityType: this.currentEntityType, id, index, pupilId: this.pupilId, groupId: this.groupId,
        });
      }
    },
    startBulkUnassign() {
      this.removingBooks = true;
      this.modal.show('unassign');
    },
    filter(tag) {
      this.tag = tag;
    },
    showBookDetails(book) {
      this.chosenBook = book;
      this.modal.show('viewBookInfo');
    },
    bulkAssign() {
      const bookIds = this.filteredBooks.map((b) => b.id);
      this.assignBooks({
        entityType: this.currentEntityType, bookIds, pupilId: this.pupilId, groupId: this.groupId,
      });
    },
    bulkUnassign() {
      this.removingBooks = false;
      const bookIds = this.filteredBooks.map((b) => b.id);
      this.removeBooks({
        entityType: this.currentEntityType, bookIds, pupilId: this.pupilId, groupId: this.groupId,
      });
    },
  },
  computed: {
    filteredBooks() {
      const re = new RegExp(this.searchTerm, 'i');
      const shouldBeAssigned = this.tag === 'assigned';
      const newArray = this.books.filter((book) => re.test(book.title)
            && (this.tag === 'all' || book.is_assigned === shouldBeAssigned)
            && (book.reading_level >= this.readingLevelStart && book.reading_level <= this.readingLevelEnd));
      this.$emit('filtered-array-update', newArray);
      return newArray;
    },
    groupedBooks() {
      return map(
        groupBy(this.filteredBooks.map((book, index) => {
          book.filteredIndex = index;
          return book;
        }), 'collection_key'),
        (group, collectionKey) => ({
          collection_key: collectionKey,
          groupTitle: (collectionKey in BOOK_GROUP_TITLES ? BOOK_GROUP_TITLES[collectionKey] : BOOK_GROUP_TITLES.default),
          books: group,
        }),
      ).sort((a, b) => {
        if (a.collection_key === b.collection_key) { return 0; }
        const aSort = Object.keys(BOOK_GROUP_TITLES).indexOf(a.collection_key);
        const bSort = Object.keys(BOOK_GROUP_TITLES).indexOf(b.collection_key);
        return aSort > bSort ? 1 : -1;
      });
    },
    pupilId() {
      return this.$route.params.id;
    },
    entityTypeString() {
      return this.currentEntityType === this.EntityType.CLASS ? 'class' : 'pupil';
    },
  },
};
</script>
<style scoped lang="scss">
  .background {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    overflow: hidden;
    justify-content: center;
  }
  .background img {
    width: 100%;
  }
  .shuffle .list-item {
    height: 250px;
    /*position: relative;*/
  }
  .shuffle .assigned {
    border: 2px solid #2ecc71;
  }
  .shuffle .unassigned {
    border: 2px solid #f1c40f;
  }
  .shuffle .list-item .list-item-inner {
    position: relative;
    height: 100%;
  }
  .shuffle .list-item .title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    color: #fff;
    font-weight: 700;
    padding: .25em .5em;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .75);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .shuffle .list-item .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .9);
    width: 100%;
    /*opacity: 0;*/
    display: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .shuffle .list-item:hover .overlay {
    /*opacity: 1;*/
    display: block;
  }
  .shuffle .list-item .overlay .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .shuffle .btn-primary {
    text-transform: none;
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
  }
  .shuffle .btn-primary .icon::before {
    vertical-align: -1px;
  }
  .list-container:not(:first-child) {
    margin-top:2em;
  }
  .list-title {
    font-weight:300;
    margin: 0 4px 0.2em 4px;
  }
  .btn-group {
    .btn-primary:not(.active) {
      border: 1px solid #249bb6;
    }
  }
  .list {
    &-actions {
      display: flex;
      justify-content: space-between;
      button:first-child {
        margin-right: 5px;
      }
      &-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
</style>
